
import { UserApi} from '../../Providers/Api'
const MODULE_NAME = 'User';

const SET_USER_NAME = "teg/SET_USER_NAME";
const SET_USER_NAME_ERROR = "teg/SET_USER_NAME_ERROR";
const INIT_USER_NAME = "teg/INIT_USER_NAME";
const REMOVE_MSG = "teg/REMOVE_MSG";

const initialState = {
   UserName : "",
   NickName : "",
   message : "",
   status : ""
};

const UserReducer = (state = initialState, action) => {
    let action_error = null;

    if (typeof action.error !== 'undefined') {
        action_error = action.error;
    }

    switch (action.type) {
        case SET_USER_NAME:          
            return {
                ...state, 
                NickName : action.nickname,
                status : "text-success",
                message : action.data
            };
        case SET_USER_NAME_ERROR:          
            return {
                ...state, 
                message : action.data,
                status : "text-danger"
            };
       case INIT_USER_NAME:          
            return {
                ...state, 
                UserName : action.uname,
                NickName : action.nickname
            };   
        case REMOVE_MSG:          
            return {
                ...state, 
                message : "",
                status : ""
            };                
        default:
            return state;
    }
};

export const updateUserName = (nickname) => async (dispatch) => {
    try {
        if(nickname.length === 0)
        {
            dispatch({ type: SET_USER_NAME_ERROR, data: "Enter Nickname." });
        }else{
            const res = await UserApi.updateUserName(nickname);
            if (res.status === 200) {
                dispatch({ type: SET_USER_NAME, nickname: nickname, data: res.message });
            } else {
                dispatch({ type: SET_USER_NAME_ERROR, data: res.message });
            }
        }
    } catch (e) {
        // console.log("Error in fetching Data");
    }
};

export const initilizeUserName = (uname , nickname) => async (dispatch) => {
    try {
        dispatch({ type: INIT_USER_NAME, uname: uname , nickname : nickname });
    } catch (e) {
        // console.log("Error in fetching Data");
    }
};

export const disAppearMsg = () => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_MSG });
    } catch (e) {
        // console.log("Error in fetching Data");
    }
};

export default UserReducer;

