import { connect } from 'react-redux';
import { markAsCompleted,setCurrentTask } from '../../../Redux/Modules/Tasks';
import Critical from '../../../Components/PageContainer/Games/Critical/Critical';

const mapStateToProps = ({ TaskReducer }) => {
    // console.log("Critical", TaskReducer);
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Critical);