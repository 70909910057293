import React, { Component } from 'react';
import './../../../Styles/Message.scss';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import CustomImg from '../../Global/CustomImg/CustomImg';


class Message extends Component {
  constructor() {
    super();
    this.state = {
      isGameFinish : false,
      isLoadGame: false,
      messageTitle: "Loading..",
      messageDescription: "",
      CurrentTask: {},
      taskId: 0
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.CurrentTask !== state.CurrentTask) {
      if (!props.isGameFinish && props.CurrentTask.Message !== undefined) {
        return {
          currentTask: props.CurrentTask,
          messageTitle: props.CurrentTask.Message.title,
          messageDescription: props.CurrentTask.Message.description,
          taskId: props.CurrentTask.id,
          isLoadGame: true
        }
      }else{
        return {
          isGameFinish : props.isGameFinish
        }
      }
    }
    return null;
  }

  render() {

    let messageTitle = this.state.messageTitle;
    let messageDescription = this.state.messageDescription;
    if(this.state.isLoadGame && (messageTitle.length === 0 || messageDescription.length === 0))
    {
      messageDescription = "NO NEW MESSAGE."
    }

    return (
      <React.Fragment>
        {!this.state.isGameFinish && <div className={"leftMessageBox"}>
          <div className="top-handle"></div>
          <div className="bottom-handle"></div>
          <div className="blackbackground">
            <div className="messageIcon">
              <i className="icon-message moonicon"></i>
            </div>
            <div className="messageContent">
              <div className="h-100">
                <CustomScrollbars>
                  <CustomImg src="red-leader.png" className="red-leader-avatar" alt="Red Leader Avatar" />
                  <ul className="listBox">
                    <li>
                      <h4>{messageTitle}</h4>
                      <p dangerouslySetInnerHTML={{ __html: messageDescription }}></p>
                    </li>
                  </ul>
                </CustomScrollbars>
              </div>
            </div>
          </div>
        </div>
        }
      </React.Fragment>
    );
  }
}

export default Message;