import React, { Component } from 'react';
import './../../../../Styles/ValidateBankData.scss';
import CustomImg from '../../../Global/CustomImg/CustomImg';

class ValidateBankData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet : [],
            option1: "",
            option2: "",
            taskId : 0 ,
            CurrentTask : {},
            LockedIn: [ "", "", "", "", "", "", ""]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
          let currentTask = props.CurrentTask;
          let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
      }

    selectValue1 = (value) => {
        this.setState({
            option1: value
        });
    }

    selectValue2 = (value) => {
        this.setState({
            option2: value
        });
    }

    validateAnswer = async () => {
        const input1 = this.refs["inputfeild1"].value;
        const input2 = this.refs["inputfeild2"].value;
        const input3 = this.refs["inputfeild3"].value;
        const input4 = this.refs["inputfeild4"].value;
        const option1 = this.state.option1;
        const option2 = this.state.option2;
        let anyCorrect = false;
        const currentValues = this.state.AnswerSheet[0].answer_key;

        if (input1.toLowerCase().trim() === currentValues.FINANCIAL_INSTITUTION_CODE.toLowerCase().trim()){
            this.state.LockedIn[0] = "active";
        }else {
            this.state.LockedIn[0] = "";
        }

        if (input2.toLowerCase().trim() === currentValues.FDIC_CERTIFICATE_NUMBER.toLowerCase().trim()){
            this.state.LockedIn[1] = "active";
        }else {
            this.state.LockedIn[1] = "";
        }

        if (input3.toLowerCase().trim() === currentValues.POWER_WATTS.toLowerCase().trim()){
            this.state.LockedIn[2] = "active";
        }else {
            this.state.LockedIn[2] = "";
        }

        if (input4.toLowerCase().trim() === currentValues.PERIGEE_KM.toLowerCase().trim()){
            this.state.LockedIn[3] = "active";
        }else {
            this.state.LockedIn[3] = "";
        }

        if (option1.toLowerCase().trim() === currentValues.STATE.toLowerCase().trim()){
            this.state.LockedIn[4] = "active";
        }else {
            this.state.LockedIn[4] = "";
        }

        if (option2.toLowerCase().trim() === currentValues.COUNTRY_OF_OPERATOR.toLowerCase().trim()){
            this.state.LockedIn[5] = "active";
        }else {
            this.state.LockedIn[5] = "";
        }

        this.setState({
            LockedIn: this.state.LockedIn
        });

        if (input1 ===  currentValues.FINANCIAL_INSTITUTION_CODE && input2 ===  currentValues.FDIC_CERTIFICATE_NUMBER && input3 ===  currentValues.POWER_WATTS
            && input4 === currentValues.PERIGEE_KM && option1.toLowerCase() ===  currentValues.STATE.toLowerCase() && option2.toLowerCase() === currentValues.COUNTRY_OF_OPERATOR.toLowerCase()) {
            anyCorrect = true;
        }

        if(anyCorrect)
        {
            setTimeout(async () => {  
                await this.props.markAsCompleted(this.state.taskId); 
            }, 1000);
        }
    }

    render() {
        return (<React.Fragment>
            <div id="container">
          <div className="page_content">
            <div className="validateBank-holder">
                <div className="h-100">
                    <div className="row h-100 d-flex justify-content-center">
                        {/* <div className="col-md-4">
                            <div className="full-height border-img">
                                <div className="text-center v-mdl">
                                    <div className="my-auto d-block">
                                                <div>
                                                    <CustomImg src="upload.svg" className="w-cut pb-5" alt="upload" />
                                                </div>
                                        <button className="blue-btn">
                                            <label htmlFor="inputBox" className="d-none">choose file</label>
                                            <input id="inputBox" type="file" />
                                            <div className="bluecolor easy-btn">
                                                <span>choose file</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="p-4">
                                <h4 className="text-uppercase pb-4">validate data</h4>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="text-uppercase" htmlFor="inputfeild1">FINANCIAL INSTITUTION ZIP CODE</label>
                                                <div className="input-outer">
                                                    <input ref={"inputfeild1"} type="text" id="inputfeild1" className="form-control text-uppercase" placeholder="ENTER FINANCIAL INSTITUTION ZIP CODE" disabled={(this.state.LockedIn[0] === "active")} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="text-uppercase" htmlFor="inputfeild2">FDIC CERTIFICATE NUMBER</label>
                                                <div className="input-outer">
                                                    <input ref={"inputfeild2"} type="text" id="inputfeild2" className="form-control text-uppercase" placeholder="ENTER FDIC CERTIFICATE NUMBER"  disabled={(this.state.LockedIn[1] === "active")}/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="text-uppercase" htmlFor="inputfeild3">POWER WATTS</label>
                                                <div className="input-outer">
                                                    <input ref={"inputfeild3"} type="text" id="inputfeild3" className="form-control text-uppercase" placeholder="ENTER POWER WATTS" disabled={(this.state.LockedIn[2] === "active")} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-uppercase" htmlFor="inputfeild4">PERIGEE KM</label>
                                                <div className="input-outer">
                                                    <input ref={"inputfeild4"} type="text" id="inputfeild4" className="form-control text-uppercase" placeholder="ENTER PERIGEE KM" disabled={(this.state.LockedIn[3] === "active")} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="text-uppercase">STATE</label>
                                                <div className="input-outer input-outer-height-auto">
                                                    <div className="dropdown select-dropdown-menu-validate">
                                                        <button  disabled={(this.state.LockedIn[4] === "active")}  className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {(this.state.option1) ? this.state.option1 : "SELECT STATE"}
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" disabled={(this.state.LockedIn[4] === "active")}>
                                                            <li onClick={() => this.selectValue1("AL")}>Alabama</li>
                                                            <li onClick={() => this.selectValue1("AK")}>Alaska</li>
                                                            <li onCli   ck={() => this.selectValue1("AZ")}>Arizona</li>
                                                            <li onClick={() => this.selectValue1("AR")}>Arkansas</li>
                                                            <li onClick={() => this.selectValue1("CA")}>California</li>
                                                            <li onClick={() => this.selectValue1("CO")}>Colorado</li>
                                                            <li onClick={() => this.selectValue1("CT")}>Connecticut</li>
                                                            <li onClick={() => this.selectValue1("DE")}>Delaware</li>
                                                            <li onClick={() => this.selectValue1("DC")}>District Of Columbia</li>
                                                            <li onClick={() => this.selectValue1("FL")}>Florida</li>
                                                            <li onClick={() => this.selectValue1("GA")}>Georgia</li>
                                                            <li onClick={() => this.selectValue1("HI")}>Hawaii</li>
                                                            <li onClick={() => this.selectValue1("ID")}>Idaho</li>
                                                            <li onClick={() => this.selectValue1("IL")}>Illinois</li>
                                                            <li onClick={() => this.selectValue1("IN")}>Indiana</li>
                                                            <li onClick={() => this.selectValue1("IA")}>Iowa</li>
                                                            <li onClick={() => this.selectValue1("KS")}>Kansas</li>
                                                            <li onClick={() => this.selectValue1("KY")}>Kentucky</li>
                                                            <li onClick={() => this.selectValue1("LA")}>Louisiana</li>
                                                            <li onClick={() => this.selectValue1("ME")}>Maine</li>
                                                            <li onClick={() => this.selectValue1("MD")}>Maryland</li>
                                                            <li onClick={() => this.selectValue1("MA")}>Massachusetts</li>
                                                            <li onClick={() => this.selectValue1("MI")}>Michigan</li>
                                                            <li onClick={() => this.selectValue1("MN")}>Minnesota</li>
                                                            <li onClick={() => this.selectValue1("MS")}>Mississippi</li>
                                                            <li onClick={() => this.selectValue1("MO")}>Missouri</li>
                                                            <li onClick={() => this.selectValue1("MT")}>Montana</li>
                                                            <li onClick={() => this.selectValue1("NE")}>Nebraska</li>
                                                            <li onClick={() => this.selectValue1("NV")}>Nevada</li>
                                                            <li onClick={() => this.selectValue1("NH")}>New Hampshire</li>
                                                            <li onClick={() => this.selectValue1("NJ")}>New Jersey</li>
                                                            <li onClick={() => this.selectValue1("NM")}>New Mexico</li>
                                                            <li onClick={() => this.selectValue1("NY")}>New York</li>
                                                            <li onClick={() => this.selectValue1("NC")}>North Carolina</li>
                                                            <li onClick={() => this.selectValue1("ND")}>North Dakota</li>
                                                            <li onClick={() => this.selectValue1("OH")}>Ohio</li>
                                                            <li onClick={() => this.selectValue1("OK")}>Oklahoma</li>
                                                            <li onClick={() => this.selectValue1("OR")}>Oregon</li>
                                                            <li onClick={() => this.selectValue1("PA")}>Pennsylvania</li>
                                                            <li onClick={() => this.selectValue1("RI")}>Rhode Island</li>
                                                            <li onClick={() => this.selectValue1("SC")}>South Carolina</li>
                                                            <li onClick={() => this.selectValue1("SD")}>South Dakota</li>
                                                            <li onClick={() => this.selectValue1("TN")}>Tennessee</li>
                                                            <li onClick={() => this.selectValue1("TX")}>Texas</li>
                                                            <li onClick={() => this.selectValue1("UT")}>Utah</li>
                                                            <li onClick={() => this.selectValue1("VT")}>Vermont</li>
                                                            <li onClick={() => this.selectValue1("VA")}>Virginia</li>
                                                            <li onClick={() => this.selectValue1("WA")}>Washington</li>
                                                            <li onClick={() => this.selectValue1("WV")}>West Virginia</li>
                                                            <li onClick={() => this.selectValue1("WI")}>Wisconsin</li>
                                                            <li onClick={() => this.selectValue1("WY")}>Wyoming</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-uppercase">COUNTRY OF OPERATOR</label>
                                                        <div className="input-outer input-outer-height-auto">
                                                            <div className="dropdown select-dropdown-menu-validate">
                                                                <button disabled={(this.state.LockedIn[5] === "active")} className="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    {(this.state.option2) ? this.state.option2 : "SELECT COUNTRY OF OPERATOR"}
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" disabled={(this.state.LockedIn[5] === "active")}>
                                                                    <li onClick={() => this.selectValue2("Austria")} >Austria</li>
                                                                    <li onClick={() => this.selectValue2("Belgium")} >Belgium</li>
                                                                    <li onClick={() => this.selectValue2("France")} >France</li>
                                                                    <li onClick={() => this.selectValue2("Germany")} >Germany</li>
                                                                    <li onClick={() => this.selectValue2("Greece")} >Greece</li>
                                                                    <li onClick={() => this.selectValue2("Italy")} >Italy</li>
                                                                    <li onClick={() => this.selectValue2("Luxembourg")} >Luxembourg</li>
                                                                    <li onClick={() => this.selectValue2("Netherlands")} >Netherlands</li>
                                                                    <li onClick={() => this.selectValue2("Norway")} >Norway</li>
                                                                    <li onClick={() => this.selectValue2("Poland")} >Poland</li>
                                                                    <li onClick={() => this.selectValue2("Spain")} >Spain</li>
                                                                    <li onClick={() => this.selectValue2("Sweden")} >Sweden</li>
                                                                    <li onClick={() => this.selectValue2("Switzerland")} >Switzerland</li>
                                                                    <li onClick={() => this.selectValue2("Ukraine")} >Ukraine</li>
                                                                    <li onClick={() => this.selectValue2("United Kingdom")} >United Kingdom</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button className="red-btn my-2" onClick={this.validateAnswer}>
                                                <div className="redcolor easy-btn">
                                                    <span>upload</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </React.Fragment>);
    }
}

export default ValidateBankData;