import React, { Component } from 'react';
import Modal from './../../Global/Modal/Modal';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import History from '../../../Providers/History';
import CustomImg from '../../Global/CustomImg/CustomImg';
import Player from '@vimeo/player';
//const games = ["Satellite Position", "Critical vs Non-Critical", "Access Threat B", "Decrypt Bank Data" , "Validate Bank Data", "Top 6 Satellite", "Reposition gate", "Crash Game", "Hacking Game", "Formula Window"];
const games = ["Critical vs Non-Critical", "Access Threat B" , "Validate Bank Data",  "Reposition gate", "Crash Game", "Hacking Game"];
const finalVideoId= "373405688";

class Video extends Component {
    constructor() {
        super();
        this.state = {
          containershow : true,  
          modal: false,
          video:'',
          taskName:'',
          top: 0 , 
          taskId  : 0,
          isExperienceVideo : false,
          isFinishVideo : false ,
          videos : [],
          isFinalShow : false ,
          isVideoModal : false,
          CurrentTask : {} ,
          isInitialize : false,
          pageType : "",
          isExperiencePlayed : false,
          isExperienceGameFinished : false,
          isGameFinish : false
        };
      }

      static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            if (props.CurrentTask !== undefined) {
                return {
                    CurrentTask: props.CurrentTask,
                    taskId: props.CurrentTask.id, 
                    isInitialize: true
                }
            }
        }
        
        if (props.isVideoModal !== state.isVideoModal) {
            if (props.isVideoModal !== undefined) {
                return {
                    isVideoModal: props.isVideoModal
                }
            }
        }

          if (props.isExperienceGameFinished) {
              return {
                  isExperienceGameFinished: true
              }
          }

          if (props.isGameFinish) {
            return {
                isGameFinish: true
            }
        }

        return null;
    }


    playVideo = async (video) => {  
       
        if(this.state.isVideoModal && video.length === 0)
        {
            this.setState({ 
                modal: !this.state.modal
            });
            return;
        }

        const type = this.state.pageType;  
        if (type === "experience") {
            //API call here
            this.props.markExperienceAsRead();
            const { isVideoPlayed } = this.state.CurrentTask; 
            if(!isVideoPlayed && !this.state.isExperiencePlayed) 
            {
                this.setState({
                    modal : false,
                    isExperiencePlayed : true
                });

                let response =  await this.props.getAllVideos(this.state.taskId);
                if (response !== "") {
                    this.setState({
                        modal: true
                    });
                    this.videoInitialization(response[response.length - 1]);
                }
            }else if(this.state.isExperiencePlayed)
            {
                this.setState({ 
                    modal: false
                });
            }
        } else if (type === "finish") {
            if(!this.state.isFinalVideoPlayed)
            {
                 this.setState({ 
                    modal: false,
                    isFinalVideoPlayed : true
                 },() => {
                    this.setState({
                        modal: true
                    },() => {
                        this.videoInitialization(finalVideoId);
                    });
                 });
            }else{
                await this.props.markExperienceCompleted();
                this.setState({ modal: !this.state.modal });
            }
        }  else {
            this.setState({ modal: !this.state.modal, video: video }, () => {
                if(this.state.modal && video.length > 0)    
                {
                    this.videoInitialization(video);
                }
                if (this.state.taskId >= 0 && !this.state.modal) {
                    this.props.markCurrentTaskVideoPlayed(this.state.taskId);
                }
            });
        }
    }

    videoInitialization = (videoId) => {
        var options = {
            url: "https://vimeo.com/" + videoId + "?" + Math.random(),
            autoplay: true,
            muted: false,
            controls : true,
            speed : true
        };
        let player = new Player("modalFrame",options);
        player.setVolume(1);
        player.on('ended', this.onVideoEnd);
    }

    onVideoEnd = async () => {
        this.playVideo('');
    };

    playExperienceVideo = () => {
        this.setState({
            modal: true,
            isExperienceVideo : true,
            isFinishVideo : false
        }, () => { 
            this.videoInitialization(this.props.video_start);
        });
    }

    playFinishVideo = () => {
        this.setState({
            modal: true,
            isFinishVideo : true,
            isExperienceVideo : false
        }, () => { 
            this.videoInitialization(this.props.video_end);
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.CurrentTask !== this.state.CurrentTask) {
           await this.initializePage();
        }

        if (prevState.isVideoModal !== this.state.isVideoModal) {
            await this.initializeAll();
        }

        if(prevState.isExperienceGameFinished !== this.state.isExperienceGameFinished)
        {
            await this.initializePage();
        }

        if(prevState.isGameFinish !== this.state.isGameFinish)
        {
            await this.initializePage();
        }
    }      

    initializeAll = async () => {
        const {isVideoPlayed, id:task_id} = this.state.CurrentTask;  
        if (task_id === undefined || task_id === "undefined") {
            let finalResponse = await this.props.getAllVideos(10);
            if (finalResponse !== "") {
                this.setState({
                    modal: false,
                    taskName: this.props.task_name,
                    video: finalResponse,
                    videos: finalResponse,
                    isFinalShow: true
                })
            }
        } else {
            let response = await this.props.getAllVideos(task_id);
            if (response !== "") {

                let updatedArray = response.splice(0, response.length - 1);
                this.setState({
                    modal: false,
                    taskName: this.props.task_name,
                    video: updatedArray,
                    videos: updatedArray
                })
            }
        }
    }

    initializePage = async () => {
        if(!this.state.isInitialize)
        {
            return;
        }

        if (!this.props.isExperienceCompleted && !this.props.isExperienceGameFinished) {
            this.setState({
                containershow: false,
                modal: true,
                isExperienceVideo: true,
                isVideoModal: false,
                pageType : "experience"
            },() => {
                if(this.state.modal)
                {
                    this.videoInitialization(this.props.video_start);
                }
            });
        } else if (!this.props.isExperienceGameFinished && this.props.isGameFinish) {
            if (this.state.taskId !== undefined && this.state.taskId > 0) {
                this.setState({
                    containershow: false,
                    modal: true,
                    isFinishVideo: true,
                    isVideoModal: false,
                    pageType: "finish"
                }, () => {
                    if (this.state.modal) {
                        this.videoInitialization(this.props.video_end);
                    }
                });
            }
        }else if(this.state.isInitialize){
            const {isVideoPlayed, id:task_id} = this.state.CurrentTask;  
            if(task_id > 0 && !this.state.isVideoModal)
            {
                let response =  await this.props.getAllVideos(task_id);
                if (response !== "") {
                    if (!isVideoPlayed) {
                        this.setState({
                            containershow : false,
                            modal: true,
                            isVideoModal : false,
                            taskName: this.props.task_name,
                            videos : response,
                            video:  response[response.length - 1],
                            pageType : task_id.toString()
                        }, () => {
                            if(this.state.modal)
                            {
                                this.videoInitialization(response[response.length - 1]);
                            }
                        })
                    
                    }
                }
            }
        }
        // const {isVideoPlayed, id:task_id} = this.props.CurrentTask;  
        // const curentVideoId = task_id;       
        // this.setState({
        //     task_id : task_id
        // });
        // let response =  await this.props.getAllVideos(curentVideoId);
        // const type = this.props.match.params.tid;
        // if(type === "experience")
        // {
        //     this.setState({
        //         containershow : false,
        //         modal: true,
        //         isExperienceVideo : true
        //     });
        //     this.videoInitialization(this.props.video_start);
        // }else if(type === "finish")
        // {
        //     this.setState({
        //         containershow : false,
        //         modal: true,
        //         isFinishVideo : true
        //     });
        //     this.videoInitialization(this.props.video_end);
        // }else if(type === undefined || type === "undefined")
        // {
        //     if (response !== "") {
        //         let updatedArray = response.splice(0,response.length-1);
        //         this.setState({
        //             modal: false,
        //             taskName: this.props.task_name,
        //             video: updatedArray,
        //             videos : updatedArray
        //         })
        //     }

        //     if(task_id === undefined || task_id === "undefined")
        //     {
        //         let finalResponse =  await this.props.getAllVideos(10);
        //         if (finalResponse !== "") {
        //             this.setState({
        //                 modal: false,
        //                 taskName: this.props.task_name,
        //                 video: finalResponse,
        //                 videos : finalResponse,
        //                 isFinalShow : true
        //             })
        //         }
        //     }
        // }else{
        //     if (response !== "") {
        //         if (!isVideoPlayed) {
        //             this.setState({
        //                 containershow : false,
        //                 modal: true,
        //                 taskName: this.props.task_name,
        //                 videos : response,
        //                 video:  response[response.length - 1]
        //             }, () => {
        //                 if(this.state.modal)
        //                 {
        //                     this.videoInitialization(response[response.length - 1]);
        //                 }
        //             })
                   
        //         }
        //     }
        // }
    }

    closeSection = () =>
    {
        this.props.closeVideoSection();
        // if(this.props.isGameFinish)
        // {
        //     History.push('/finish');
        // }else{
        //     History.push('/task' + this.state.task_id);
        // }
    }

    render() {
        return (<React.Fragment>
            <div className={"video-overlay"  + (this.state.isVideoModal ? " d-block" : " d-none")}></div>
            <div className="videos-holder video-holder mainBox video-popup" style={{display : (this.state.isVideoModal ? "block" : "none")}}>
                <div className="play-icon">
                    <em className="icon-playicon moonicon"></em>
                </div>
                <div className="container-close" onClick={this.closeSection}>
                    <CustomImg src="gradiant-close.svg" className="container-close float-right" alt="gradiant-close" />                
                </div>
                <CustomScrollbars>
                   <div id="videos-scrollbar">
                    <div className="video-Content">
                            <div className="row">
                                <div key={"experience"} className="col-4">
                                    <div className="video-list">
                                        <div>
                                            <div className="video-img">
                                                <div className="content">                                                      
                                                        <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={this.playExperienceVideo}></div>
                                                        {this.props.video_start && 
                                                            <iframe src={"https://player.vimeo.com/video/" + this.props.video_start + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                        }
                                                </div>
                                            </div>
                                            <h4 className="title" onClick={this.playExperienceVideo}>Experience Video</h4>
                                        </div>
                                    </div>
                                </div>
                                {this.state.videos && this.state.videos.map((data, index) => {
                                    return (<div key={index} className="col-4">
                                        <div className="video-list">
                                            <div>
                                                <div className="video-img">
                                                    <div className="content">                                                      
                                                        <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={() => this.playVideo(data)}></div>
                                                        {data && 
                                                            <iframe src={"https://player.vimeo.com/video/" + data + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                        }
                                                    </div>
                                                </div>
                                                <h4 onClick={() => this.playVideo(data)} className="title">{games[index]}</h4>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                                {this.props.isGameFinish &&
                                    <div key={"finish"} className="col-4">
                                        <div className="video-list">
                                            <div>
                                                <div className="video-img">
                                                    <div className="content">                                                      
                                                            <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={this.playFinishVideo}></div>
                                                            {this.props.video_end && 
                                                                <iframe src={"https://player.vimeo.com/video/" + this.props.video_end + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                            }
                                                    </div>
                                                </div>
                                                <h4 className="title" onClick={this.playFinishVideo}>Finish Video</h4>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                    </div>
                    
                </div>
                
                </CustomScrollbars>
                
            </div>
            {(this.state.modal )&& <Modal isOpen={this.state.modal} modalClass={"bd-example-modal-xl video-modal"} dialogClass={"modal-lg modal-dialog-centered"} >
                    <div className="modal-body">
                    <button onClick={() => this.playVideo('')} className="close-gradiant" data-dismiss="modal" aria-label="Close">
                        <CustomImg src="gradiant-close.svg" className="" alt="gradiant-close" />
                    </button>
                        
                            <div className="w-100 h-100">
                                <div id="modalFrame" className="w-100 h-100 modalframe"></div>
                                {/* <iframe width="100%" height="100%" src="" allowFullScreen></iframe> */}
                                {/* {this.state.video && !this.state.isExperienceVideo && !this.state.isFinishVideo && 
                                <iframe ref="iframe" width="100%" height="100%" src={this.state.video + "?"+ Math.floor((Math.random() * 1000) + 1) + "&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} 
                                {this.state.isExperienceVideo && <iframe ref="iframe" width="100%" height="100%" src={this.props.video_start + "?"+ Math.floor((Math.random() * 1000) + 1) +"&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
                                {this.state.isFinishVideo && <iframe ref="iframe" width="100%" height="100%" src={this.props.video_end + "?"+ Math.floor((Math.random() * 1000) + 1) +"&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} */}
                            </div>
                        
                    </div>
                    </Modal>
                }
        </React.Fragment >);
    }
}

export default Video;