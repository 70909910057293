import { connect } from 'react-redux';


import EndGame from '../../../Components/PageContainer/EndGame/EndGame';

import { markExperienceCompleted } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ TaskReducer }) => {     
    return {
            isExperienceGameFinished:TaskReducer.isExperienceGameFinished ,
            bonusPoint : TaskReducer.bonusPoint          
        };
};

const mapDispatchToProps = dispatch => ({
    markExperienceCompleted: () => dispatch(markExperienceCompleted())
});

export default connect(mapStateToProps, mapDispatchToProps)(EndGame);