import { connect } from 'react-redux';


import Footer from '../../../Components/Layout/Footer';
import { markLastActivity } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ TaskReducer }) => {   

    return {
            totalScore:TaskReducer.totalScore,   
            isGameFinish : TaskReducer.isGameFinish,
            isEnrolled : TaskReducer.isEnrolled        
        };
};

const mapDispatchToProps = dispatch => ({
    markLastActivity: () => dispatch(markLastActivity())
});


export default connect(mapStateToProps, mapDispatchToProps)(Footer);