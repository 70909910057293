import queryString from 'query-string';
import urlParser from 'url';
//import history from '../Providers/History';
//import store from '../Redux/Store';
import Storage from '../Providers/Storage';
import Progress from "react-progress-2";
export default class Http {
    constructor({ host = false, options = {} }) {
        this.host = host;// host ? urlParser.parse(host).protocol + '//' + urlParser.parse(host).host : false;
        // console.log("http Host ",this.host);
        this.options = options;
    }

    // Override this in implementations to set headers at request-time
    getHeaders() {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
        return headers;
    }

    resolveUrl(url) {
        if (!this.host) return url;

        return urlParser.resolve(this.host, urlParser.parse(url).path);
    }

    parseJsonResponse = (json) => {
        // console.log(" HTTP parseJsonResponse ");
        if (typeof json.data === 'undefined' && !json.token) {
            throw new Error("No data member specified on API response.");
        }
        return json;
    };

    parseJsonError = (json, status) => {

        if (status === 401) {
            /*if (!store.getState().Auth.isAuthenticated && window.location.pathname !== '/login') {
                history.push('/login');
            } else { */
            // Mav Note: assume based on the conditions
            // the error was a result of an invalaid session.
            // If at the login page, throw errors
            // This is hackey but prevents a butt load of error toasts
            // we're currenty seeing on the dashboard when timing out
            throw { ...json.errors, status: status };
            //}
        } else {
            // All other status codes, simply throw
            throw { ...json.errors, status: status };
        }
    };

    async handleRequest(url, options = {}, loader_show = true) {
        if (loader_show === true) {
            Progress.show();
        }
        const requestOptions = { ...this.options, ...options };
        requestOptions.headers = this.getHeaders();
        const token = Storage.get('access_token');
        // console.log(" Get access Token ",token);
        if (token) {
            requestOptions.headers = { ...requestOptions.headers, Authorization: `${token}` };
        }
        try {
            const response = await fetch(this.resolveUrl(url), requestOptions);
            const json = await response.json();

            if (loader_show === true) {
                Progress.hide();
            }
            // Throw responses that returned HTTP error codes
            if (response.status > 299) throw this.parseJsonError(json, response.status);
            return this.parseJsonResponse(json);
        } catch (e) {
            console.log(e);
            Progress.hide();
        }

    }

    handleResponse = (response) => {

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
    };


    get(url, queryObject = false, loader_show = true) {
        const query = queryObject ? `?${queryString.stringify(queryObject)}` : '';
        return this.handleRequest(url + query, '', loader_show);
    }

    post(url, body, loader_show = true) {
        return this.handleRequest(url, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                "ContentType": "application/json"
            }
        }, loader_show);
    }

    put(url, body, loader_show = true) {
        return this.handleRequest(url, {
            method: 'put',
            body: JSON.stringify(body)
        }, loader_show);
    }

    delete(url, loader_show = true) {
        return this.handleRequest(url, { method: 'delete' }, loader_show);
    }

    upload(url, data) {
        url = this.resolveUrl(url);
        return new Promise((resolve, reject) => {
            const formdata = new FormData();
            for (const key in data) {
                formdata.append(key, data[key]);
            }
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url);

            const token = Storage.get('access_token');
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);

            xhr.onload = () => {
                const json = JSON.parse(xhr.response);
                if (xhr.status > 299) {
                    reject({ ...json.errors, status: json.status });
                } else {
                    resolve(json);
                }
            };

            xhr.onabort = () => reject(JSON.parse(xhr.response));
            xhr.onerror = () => reject(JSON.parse(xhr.response));

            xhr.send(formdata);
        });
    }

    getBlob(url, params) {
        url = this.resolveUrl(url);

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url + params);

            const token = Storage.get('access_token');
            xhr.setRequestHeader('Accept', 'application/pdf');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.responseType = 'blob';

            xhr.onload = () => {
                const json = xhr.response;
                if (xhr.status > 299) {
                    reject({ ...json.errors, status: json.status });
                } else {
                    resolve(json);
                }
            };

            xhr.onabort = () => reject(JSON.parse(xhr.response));
            xhr.onerror = () => reject(JSON.parse(xhr.response));

            xhr.send();
        });
    }

    getCSV(url, params) {
        url = this.resolveUrl(url);

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const query = params ? `?${queryString.stringify(params)}` : '';
            xhr.open("GET", url + query);

            const token = Storage.get('access_token');
            xhr.setRequestHeader('Accept', 'application/csv');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.responseType = 'blob';

            xhr.onload = () => {
                const response = xhr.response;
                if (xhr.status > 299) {
                    reject({ ...response.errors, status: response.status });
                } else {
                    resolve(response);
                }
            };

            xhr.onabort = () => reject(xhr.response);
            xhr.onerror = () => reject(xhr.response);

            xhr.send();
        });
    }
}