import React, { Component } from 'react';
import './../../../../Styles/HackingGame.scss';
import CustomImg from '../../../Global/CustomImg/CustomImg';

class HackingGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Credentials: [],
            CurrentTask : {},
            Msg : ""
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
          let currentTask = props.CurrentTask;
          let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                }
            }
        }
        return null;
      }

    validateCredentials = async () => {
        const UserName = this.refs["txtUserName"].value;
        const Password = this.refs["txtPassword"].value;
        if (UserName.length > 0 && Password.length > 0) {
            const answers = this.state.AnswerSheet[0].answer_key;
            let anyCorrect = false;
            answers.map((data, key) => {
                if (data.USERNAME.toLowerCase().trim() === UserName.toLowerCase().trim() && data.PASSWORD.toLowerCase().trim() === Password.toLowerCase().trim()) {
                    anyCorrect = true;
                }
            });

            if (anyCorrect) {
                await this.props.markAsCompleted(this.state.taskId);
            } else {
                this.setState({
                    Msg: "Please enter valid credentials."
                });
            }
        } else {
            this.setState({
                Msg: "UserName & Password required."
            });
        }
    }

    render() {
        return (<React.Fragment>
            <div id="container">
                <div className="page_content">
                    <div className="HackingGame-holder d-flex justify-content-center">
                        <div className="HackingGame-holder-box">
                            <div className="content">
                                <h4 className="text-uppercase text-center">locc login</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="text-uppercase pl-4" htmlFor="threat level">username</label>
                                            <div className="input-outer">
                                                <input ref="txtUserName" type="text" className="form-control" id="threat level"
                                                    aria-describedby="emailHelp" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="text-uppercase pl-4" htmlFor="count">password</label>
                                            <div className="input-outer">
                                                <input ref={"txtPassword"} type="password" className="form-control" id="count" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="pl-4 text-uppercase position-relative">need a password hint? <button type="button"  data-toggle="modal" data-target="#exampleModal"> click here.</button></p>
                                <p className="pl-4 text-uppercase position-relative text-danger text-center ">{this.state.Msg}</p>
                                <div className="text-center col">
                                    <button className="red-btn" onClick={this.validateCredentials}>
                                        <div className="redcolor easy-btn">
                                            <span>login</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="border-box">
                            <div className="modal-content">
                                <div className="modal-header border-0 d-block">
                                    <button type="button" className="close px-0 py-2 float-left" data-dismiss="modal" aria-label="Close">
                                        <CustomImg src="close.png" className="img-fluid" alt="close-big" />
                                    </button>
                                </div>
                                <div className="modal-body text-uppercase">
                                    call and order my favorite chinese takeout dish!
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>);
    }
}

export default HackingGame;