import React, { Component } from 'react';
import CustomImg from '../../Global/CustomImg/CustomImg';
class FinalHint extends Component {

    constructor() {
        super();
        this.state = {
            point: 0
        };
    }

    componentDidMount() {

    }
    showWarning = (type) => {
        let point = 0;
        if (type === "Easy") {
            point = 2;
        } else if (type === "Medium") {
            point = 5;
        } else if (type === "Hard") {
            point = 10;
        } else {
            point = 13;
        }
        this.setState({ modal: !this.state.modal, point: point });
    }

    render() {
        return (
            <React.Fragment>
                <button className="close" onClick={this.props.ShowHint}>
                    <CustomImg src="gradiant-close.svg" className="img-close" alt="img-close" />
                </button>
                <div id="taskBoxnew" className={"step-three " + (this.props.showFinalHint) ? "d-flex align-items-center" : "d-none"} >
                    <div className="contentHint">
                        <div id="scrollbar" className="container-fluid">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="pr-5">
                                        {this.props.clueData &&
                                            <React.Fragment>
                                                <h1>
                                                    <CustomImg src={this.props.clueData.image} className="mCS_img_loaded"
                                                        alt="" />
                                                    {this.props.clueData.title} </h1>
                                                <hr />
                                                <div className="p-3">

                                                    <p>{this.props.clueData.description}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="boxImg">
                                        <div className="content">
                                            <CustomImg src="player-img-01.jpg" className="img-obj-fit-cover"
                                                alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FinalHint;