import React, { Component } from 'react';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import History from '../../../Providers/History';
import Modal from './../../Global/Modal/Modal';
import CustomImg from '../../Global/CustomImg/CustomImg';

class Files extends Component {
    constructor() {
        super();
        this.state = {
            files: [],
            task_id : 0,
            isFileModal : false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isFileModal !== state.isFileModal) {
            if (props.isFileModal !== undefined) {
                return {
                    isFileModal: props.isFileModal
                }
            }
        }
        return null;
    }

    async componentDidMount() {

        const {id:task_id} = this.props.currentTask;       
        this.setState({
            task_id : task_id
        });

        if (this.props.isExperienceGameFinished) {
            const filesCollection = await this.props.getAllFiles(10);
            this.setState({
                files: filesCollection
            });
        } else {
            const { id: task_id } = this.props.currentTask;
            const filesCollection = await this.props.getAllFiles(task_id);
            this.setState({
                files: filesCollection
            });
        }
    }

    download(file) {
        window.open(require('../../../datasets/' + file));
    }

    closeSection = () =>
    {
        this.props.closeFileSection();
        // if(this.props.isGameFinish)
        // {
        //     History.push('/finish');
        // }else{
        //     History.push('/task' + this.state.task_id);
        // }
    }
    
    render() {
        return (<React.Fragment>
            <div className={"videos-holder mainBox files-holder" + (this.state.isFileModal ? " d-block" : " d-none")}>
                <div className="play-icon">
                    <em className="icon-foldericon moonicon"></em>
                </div>
                <div className="container-close" onClick={this.closeSection}>
                    <CustomImg src="gradiant-close.svg" className="container-close float-right" alt="gradiant-close" />
                </div>
                <CustomScrollbars>
                    <div id="videos-scrollbar">
                        <div className="video-Content files-content">
                            <div className="row">
                                {this.state.files && this.state.files.map((data, index) => {
                                    const fileName = data.split('.');
                                    return (<div key={index} className="col">
                                        <div className="video-list position-relative">
                                            <div>
                                                <div className="files-img">
                                                    <CustomImg src="gradiant-folder.svg" alt="files-img" />
                                                </div>
                                                <h4 role="link" onClick={() => this.download(data)} className="title text-truncate stretched-link">{fileName[0]}</h4>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </CustomScrollbars>
            </div>
            <div className={"file-overlay"  + (this.state.isFileModal ? " d-block" : " d-none")}></div>
        </React.Fragment>);
    }
}

export default Files;