import { connect } from 'react-redux';
import { markAsCompleted,setCurrentTask } from '../../../Redux/Modules/Tasks';
import HackingGame from '../../../Components/PageContainer/Games/HackingGame/HackingGame';

const mapStateToProps = ({ TaskReducer }) => {
    // console.log("HackingGame", TaskReducer);
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(HackingGame);