import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import './../../../../Styles/RepositionGate.scss';
import Draggable from 'react-draggable';
import { const_basePage } from '../../../Global/Constants/constants';
import CustomImg from '../../../Global/CustomImg/CustomImg';

const RePositionSattellite = [
  { id: 1, sattelliteName: "sattelliteName1", satelliteNormalImage: "repositionSatellite-one.svg", satelliteHoverImage: "repositionSatellite-one-hover.svg", satellitegreenImage: "repositionSatellite-one-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 2, sattelliteName: "sattelliteName2", satelliteNormalImage: "repositionSatellite-two.svg", satelliteHoverImage: "repositionSatellite-two-hover.svg", satellitegreenImage: "repositionSatellite-two-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 3, sattelliteName: "sattelliteName3", satelliteNormalImage: "repositionSatellite-three.svg", satelliteHoverImage: "repositionSatellite-three-hover.svg", satellitegreenImage: "repositionSatellite-three-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 4, sattelliteName: "sattelliteName4", satelliteNormalImage: "repositionSatellite-four.svg", satelliteHoverImage: "repositionSatellite-four-hover.svg", satellitegreenImage: "repositionSatellite-four-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 5, sattelliteName: "sattelliteName5", satelliteNormalImage: "repositionSatellite-five.svg", satelliteHoverImage: "repositionSatellite-five-hover.svg", satellitegreenImage: "AssessThreatB-five-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 6, sattelliteName: "sattelliteName6", satelliteNormalImage: "repositionSatellite-six.svg", satelliteHoverImage: "repositionSatellite-six-hover.svg", satellitegreenImage: "repositionSatellite-six-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
  { id: 7, sattelliteName: "sattelliteName7", satelliteNormalImage: "repositionSatellite-saven.svg", satelliteHoverImage: "repositionSatellite-saven-hover.svg", satellitegreenImage: "repositionSatellite-saven-green.svg", isDraggable: false, isComplete: false, correct_point_postion: 0, user_point_postion: '', controlledPosition: { x: 0, y: 0 }, 'tooltip_text': '' },
];
class RepositionGate extends Component {

  constructor(props) {
    super(props);


    this.state = {
      OverlayHeight: 0,
      Satellites: RePositionSattellite,
      taskId: 0,
      placedItem: [ { pointid : "point_1" , isPlaced : false } ,
      { pointid : "point_2" , isPlaced : false } ,
      { pointid : "point_3" , isPlaced : false } ,
      { pointid : "point_4" , isPlaced : false } ,
      { pointid : "point_5" , isPlaced : false } ,
      { pointid : "point_6" , isPlaced : false } ,
      { pointid : "point_7" , isPlaced : false }],
      controlledPosition: { x: 0, y: 0 },
      deltaPosition: { x: 0, y: 0 },
      originalPosition: {},
      points: {},
      AnswerSheet: [],
      CurrentTask: {},
      allowSimulation: false,
      isBoxVisible: false
    };

    this.contentBox = React.createRef();
    let drag_postion = {};
    let curret_drag_sattellite = '';
    this.sattellites_arr = RePositionSattellite;

  }

  static getDerivedStateFromProps(props, state) {
    if (props.CurrentTask !== state.CurrentTask) {
      let currentTask = props.CurrentTask;
      let currentTaskSolutions = currentTask.Solutions;
      if (currentTask.Solutions !== undefined) {
        const answers = props.CurrentTask.Solutions[0].answer_key;
        const updatedArray = RePositionSattellite;
        answers.map((data, index) => {
          updatedArray.filter(m => {
            if (m.id == data.ID) {
              if (updatedArray[index] !== undefined) {
                updatedArray[index].correct_point_postion = data.POSITION;
                updatedArray[index].tooltip_text = data.TITLE;
              }
            }
          });
        });
        return {
          AnswerSheet: currentTaskSolutions,
          taskId: currentTask.id,
          Satellites: updatedArray
        }
      }
    }
    return null;
  }

  async componentDidMount() {
    let currentTask = this.props.CurrentTask;
    let currentTaskSolutions = currentTask.Solutions;

    this.setState({
      originalPosition: ReactDOM.findDOMNode(this.refs["satellite_1"]).getBoundingClientRect(),
      OverlayHeight: this.contentBox.current.offsetHeight - 38,
      taskId: this.props.CurrentTask.id,
      AnswerSheet: currentTaskSolutions,
    });

    setTimeout(() => {
      if (this.refs["point_1"] !== undefined) {
        let point_value = ReactDOM.findDOMNode(this.refs["point_1"]).getBoundingClientRect();

        let point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        let point_postion = {};
        point_postion = { 'point_1': point_postion_value };

        point_value = ReactDOM.findDOMNode(this.refs["point_2"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        point_postion = { ...point_postion, 'point_2': point_postion_value };

        point_value = ReactDOM.findDOMNode(this.refs["point_3"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top };
        point_postion = { ...point_postion, 'point_3': point_postion_value };


        point_value = ReactDOM.findDOMNode(this.refs["point_4"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        point_postion = { ...point_postion, 'point_4': point_postion_value };

        point_value = ReactDOM.findDOMNode(this.refs["point_5"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        point_postion = { ...point_postion, 'point_5': point_postion_value };

        point_value = ReactDOM.findDOMNode(this.refs["point_6"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        point_postion = { ...point_postion, 'point_6': point_postion_value };

        point_value = ReactDOM.findDOMNode(this.refs["point_7"]).getBoundingClientRect();
        point_postion_value = { 'bottom': point_value.bottom, 'height': point_value.height, 'left': point_value.left, 'right': point_value.right, 'top': point_value.top, 'width': point_value.width, 'x': point_value.left, 'y': point_value.top, 'satellite_id': '' };
        point_postion = { ...point_postion, 'point_7': point_postion_value };
        this.setState({
          points: point_postion
        });
      }
    }, 1000);


  }

  handleStart = (index) => {

    let newSat = this.state.Satellites.filter((data) => { return data.id == index })[0];
    this.curret_drag_sattellite = index;
    newSat.isDraggable = false;
    this.setState({ ...this.state.Satellites, newSat });
  }

  handleDragold = (e, ui) => {

    const { x, y } = this.state.deltaPosition;
    this.setState({
      controlledPosition: {
        x: ui.deltaX,
        y: ui.deltaY,
      }
    });
    this.drag_postion = {
      x: ui.deltaX,
      y: ui.deltaY,
    };
  };

  handleDrag = (e, position) => {

    const { x, y } = position;


    this.drag_postion = { x: x, y: y };


    this.sattellites_arr.filter((data) => { return data.id == this.curret_drag_sattellite }).map((data) => {
      data.controlledPosition = this.drag_postion;
    });


  };

  handleStop = (e, index) => {
    //console.log(ReactDOM.findDOMNode(this.refs["point_3"]).getBoundingClientRect());
    //console.log(ReactDOM.findDOMNode(this.refs["point_4"]).getBoundingClientRect());
    //console.log(ReactDOM.findDOMNode(this.refs["satellite_1"]).getBoundingClientRect());

    //// console.log(this.drag_postion);
    let satellite_place = false;
    let satellite_postion = ReactDOM.findDOMNode(this.refs["satellite_" + index]).getBoundingClientRect();
    console.log(satellite_postion);
    //console.log(satellite_postion);
    //// console.log(satellite_postion);
    //let satellite_postion = e;
    //let satellite_postion = ReactDOM.findDOMNode(this.refs["satellite_1"]).getBoundingClientRect();;
    //alert(satellite_postion.left+':::'+this.state.points['point_1'].left+"::"+satellite_postion.top+':::'+this.state.points['point_1'].top);
    //// console.log(e);
    //// console.log(satellite_postion);
    // let left_postion_higher = Number(satellite_postion.left + satellite_postion.width);
    // let left_postion_lower = Number(satellite_postion.left - satellite_postion.width);
    // let top_postion_higher = Number(satellite_postion.top + satellite_postion.height);
    // let top_postion_lower = Number(satellite_postion.top - satellite_postion.height);

    let variation_top = 15;
    let variation_left = 15;
    // let left_postion_higher = Number(satellite_postion.left + variation_left);
    // let left_postion_lower = Number(satellite_postion.left - variation_left);
    // let top_postion_higher = Number(satellite_postion.top + variation_top);
    // let top_postion_lower = Number(satellite_postion.top - variation_top);

    // let left_postion_higher = Number(satellite_postion.left + satellite_postion.width);
    // let left_postion_lower = Number(satellite_postion.left);
    // let top_postion_higher = Number(satellite_postion.top + satellite_postion.height);
    // let top_postion_lower = Number(satellite_postion.top);


    let satellite_center_x = Number(satellite_postion.left) + Number(satellite_postion.width / 2);
    let satellite_center_y = Number(satellite_postion.top) + Number(satellite_postion.height / 2);

    let left_postion_higher = satellite_center_x + variation_left
    let left_postion_lower = satellite_center_x - variation_left
    let top_postion_higher = satellite_center_y + variation_top;
    let top_postion_lower = satellite_center_y - variation_top;

    let point_found = '';
    let point_already_occupied = false;

    let latest_sattellite_state = this.state.Satellites;

    Object.keys(this.state.points).map((key) => {
      if (point_found == '') {



        //if (this.state.points[key].left > left_postion_lower && this.state.points[key].left < left_postion_higher
        //) {
        // if (this.state.points[key].left > left_postion_lower && this.state.points[key].left < left_postion_higher &&
        //   Number(this.state.points[key].top) > top_postion_lower && Number(this.state.points[key].top) < top_postion_higher
        // ) {
        if (this.state.points[key].left > left_postion_lower && this.state.points[key].left < left_postion_higher &&
          Number(this.state.points[key].top) > top_postion_lower && Number(this.state.points[key].top) < top_postion_higher
        ) {
          console.log(this.state.placedItem);
          let updatedPlacedItem = this.state.placedItem;
          let currentObj = updatedPlacedItem.filter(m=> m.pointid.toLowerCase() === key.toLowerCase());
          if(currentObj && currentObj[0].isPlaced)
          {
            return;
          }else{
           
            if (currentObj) {
              currentObj[0].isPlaced = true;
              this.setState({
                placedItem: updatedPlacedItem
              });
            }

            point_found = key;
            satellite_place = true;
            latest_sattellite_state.filter((data) => { return data.id == index }).map((data) => {

              //data.user_point_postion = point_found;
              data.user_point_postion = point_found.replace('point_', '');
              data.controlledPosition = this.drag_postion;;
            });
            this.updateSattelliteState(latest_sattellite_state);
          }
        }
      }
    });

    if (satellite_place === false) {
      let latest_sattellite_state = this.state.Satellites;
      latest_sattellite_state.filter((data) => { return data.id == this.curret_drag_sattellite }).map((data) => {
        data.controlledPosition = { x: 0, y: 0 };
        data.user_point_postion = '';
      });
      this.updateSattelliteState(latest_sattellite_state);
    }

    this.checkAllowSimulation();
  }
  checkAllowSimulation() {

    this.setState({
      allowSimulation: true
    });
    this.state.Satellites.map((data, index) => {
      if (data.user_point_postion == '') {
        this.setState({
          allowSimulation: false
        });

      }
      return;
    })
  }
  updateSattelliteState(latest_sattellite_state) {
    this.setState({
      Satellites: latest_sattellite_state
    });
  }
  adjustSattellitePostiontoPoint(point_no) {
    // alert(point_no);
    let satellite_postion = this.state.originalPosition;

    let point_x = this.state.points[point_no].left;
    let point_y = this.state.points[point_no].top;
    let satellite_x = satellite_postion.left;
    let satellite_y = satellite_postion.top;
    let satellite_width = satellite_postion.width / 2;
    let satellite_height = satellite_postion.height / 2;
    let correct_postion_x = 0;
    let correct_postion_y = 0;
    if (point_x > satellite_x) {
      correct_postion_x = point_x - (satellite_x + satellite_width);
    } else {
      correct_postion_x = (satellite_x + satellite_width) - point_x;
    }

    if (point_y > satellite_y) {

      correct_postion_y = ((satellite_y - satellite_height) - point_y) - satellite_postion.height;
      correct_postion_y = (satellite_y - point_y);
    } else {
      correct_postion_y = (point_y - (satellite_y - satellite_height)) + satellite_postion.height;
    }

    this.setState({
      controlledPosition: {
        x: correct_postion_x,
        y: correct_postion_y,
      }
    });
  }

  runSimulation() {
    let sat_postions = this.state.Satellites;
    let points_state = this.state.points;
    let newUpdatedPlacedItem = this.state.placedItem;

    let game_finish = true;
    sat_postions.map((data, kindex) => {
      if (data.correct_point_postion != data.user_point_postion) {
        let sat_user_postion = data.user_point_postion;
        let getCurrentObject = newUpdatedPlacedItem.filter(m=> m.pointid.toLowerCase() === "point_" + sat_user_postion.toLowerCase());
        if (getCurrentObject) {
          getCurrentObject[0].isPlaced = false;
        }
        Object.keys(points_state).filter((key) => { return points_state[key].satellite_id != '' }).map((data) => {
          if (sat_user_postion == data.replace('point_', '')) {
            this.state.points[data].satellite_id = '';
          }
        });
        data.controlledPosition = { x: 0, y: 0 };
        data.user_point_postion = '';
        game_finish = false;
      }
    });
    this.setState({
      Satellites: sat_postions
    });
    if (game_finish === true) {
      this.setState({ isBoxVisible: true });
      setTimeout(() => {
        this.setState({ isBoxVisible: false });
        this.props.markAsCompleted(this.props.CurrentTask.id);
      }, 5000);

    } else {
      this.setState({
        allowSimulation: false,
        placedItem : newUpdatedPlacedItem
      });
    }
  }

  render() {
    const { isBoxVisible } = this.state;
    return (<React.Fragment>
      <div id="container">
        <div className="page_content">
          <div className="reposition-holder">
            <div className="back-circle-box" ref={this.contentBox}>
              <div className="circle border-0">
                <div className="circle">
                  <div className="circle">
                    <div className="circle transparent-background">
                      <div className="position-txt-left">Position</div>   
                      <div className="position-txt-right">Position</div>                   
                      <div className="lineDots linedotsOne" style={{ height: this.state.OverlayHeight }}>
                        <div className="digite-box">-7</div>
                      </div>
                      <div className="blackbox-label"><span>high orbit</span></div>
                      <div className="lineDots right linedotsTwo" style={{ height: this.state.OverlayHeight }}>
                        <div className="digite-box">7</div>
                        <div ref="point_4" id="point_4" data-title='4' className="line-points cust-tooltip" style={{ top: '45%' }}></div>

                      </div>
                      <div className="circle transparent-background">
                        <div className="lineDots linedotsThree" style={{ height: this.state.OverlayHeight }}>
                          <div className="digite-box">-5</div>

                          <div ref="point_1" id="point_1" data-title='1' className="line-points cust-tooltip" style={{ top: '47%' }} data-place="bottom" ></div>

                        </div>
                        <div className="blackbox-label"><span>medium orbit</span></div>
                        <div className="lineDots right linedotsfour" style={{ height: this.state.OverlayHeight }}>
                          <div className="digite-box">5</div>
                          <div ref="point_3" id="point_3" data-title='3' className="line-points cust-tooltip" style={{ top: '40%' }}></div>
                          <div ref="point_7" id="point_7" data-title='7' className="line-points cust-tooltip" style={{ top: '77%' }}></div>
                        </div>
                        <div className="circle transparent-background midium-padding">
                        
                          <div className="lineDots linedotsFive" style={{ height: this.state.OverlayHeight }}>
                            <div className="digite-box">-3</div>
                            <div ref="point_2" id="point_2" data-title='2' className="line-points cust-tooltip" style={{ top: '42%' }}></div>
                            <div ref="point_5" id="point_5" data-title='5' className="line-points cust-tooltip" style={{ top: '71%' }}></div>
                          </div>
                          <div className="blackbox-label"><span>low orbit</span></div>
                          <div className="lineDots right linedotsSix" style={{ height: this.state.OverlayHeight }}>
                            <div className="digite-box">3</div>
                            <div ref="point_6" id="point_6" data-title='6' className="line-points cust-tooltip" style={{ top: '71%' }}></div>
                          </div>
                          <CustomImg src="glob-img.svg" className="img-green" alt="" />
                          <div className="dragdown-text">drag and drop satellites</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dragLeftpart">
              <div className="contentBox color-back">
                <div className="contentBox-inner text-uppercase text-center">
                  drag and drop satellites
                </div>
              </div>
              <div className="contentBox">
                <div className="contentBox-inner">
                  <ul>
                    <li>
                      SPACE SCIENCE / TECHNOLOGY DEVELOPMENT and COMMUNICATIONS MARITIME TRACKING orbit at the same speed.
                    {/* <span className="text-capitalize"> </span> */}
                    </li>
                    <li>
                      COMMUNICATIONS FINANCIAL AND COMMUNICATIONS CIVIL are in the same position.
                    </li>
                    <li>
                      SPACE SCIENCE / TECHNOLOGY DEVELOPMENT is in a negative position.
                    </li>
                    <li>
                      TECHNOLOGY DEVELOPMENT / EDUCATIONAL is closer to Earth than EARTH OBSERVATIONS / EARTH SCIENCE / WEATHER.
                    </li>
                    <li>
                      COMMUNICATIONS MARITIME TRACKING never has a view of SPACE SCIENCE / TECHNOLOGY DEVELOPMENT.
                    </li>
                    <li>
                      COMMUNICATIONS CIVIL has the fastest orbit speed.
                    </li>
                    <li>
                      Navigational / Global Positioning is in the same position as the satellite with a launch mass of 310 kg (Hint: Use the Alteryx workflow from the previous task).
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="dragRightpart">
              <div className="contentBox">
                <div className="contentBox-inner text-center text-uppercase">
                  <p>LOW ORBIT SATELLITES</p>
                  <p>=</p>
                  <p>HIGH SPEED ORBIT</p>
                </div>
              </div>
              <div className="contentBox">
                <div className="contentBox-inner text-center text-uppercase">
                  <p>MEDIUM ORBIT SATELLITES</p>
                  <p>=</p>
                  <p>MEDIUM SPEED ORBIT</p>
                </div>
              </div>
              <div className="contentBox">
                <div className="contentBox-inner text-center text-uppercase">
                  <p>HIGH ORBIT SATELLITES</p>
                  <p>=</p>
                  <p>LOW SPEED ORBIT</p>
                </div>
              </div>
              <button className="red-btn w-100" disabled={!this.state.allowSimulation} onClick={this.runSimulation.bind(this)}>
                <div className="redcolor easy-btn">
                  <span>run simulation</span>
                </div>
              </button>

              {/* {this.state.Satellites.map((x) => {
                return (<div>
                  ID: {x.id}, user_postion: {x.user_point_postion},correct_postion:{x.correct_point_postion}
                </div>)
              })} */}

            </div>
            <div className="dragBottompart">
              {this.sattellites_arr.map((data) => {
                return (
                  <div key={data.id}>
                    <Draggable position={data.controlledPosition} onStart={() => this.handleStart(data.id)} onDrag={this.handleDrag} onStop={(e) => this.handleStop(e, data.id)} scale={const_basePage.scale}>
                      <div ref={"satellite_" + data.id} className="itemBox cust-tooltip" data-title={data.tooltip_text}  >
                        <CustomImg src={data.satelliteNormalImage} className="img-normal" alt="" />
                        <CustomImg src={data.satelliteHoverImage} className="img-hover-active" alt="" />
                        <CustomImg src={data.satellitegreenImage} className="img-green" alt="" />
                      </div>
                    </Draggable>
                  </div>
                )
              })}
            </div>
            <div className={`repostion-graph position-absolute ${isBoxVisible ? '' : ' d-none'} `}>
              <div className="row justify-content-center align-items-center h-100 text-white">
                <div className="col-4 text-right pt-150">
                  <div className="graph-name">communications financial</div><br></br>
                  <div className="graph-name">communications civil</div><br></br>
                  <div className="graph-name">communcations maritime tracking</div><br></br>
                  <div className="graph-name">earth observations / earth science / weather</div><br></br>
                  <div className="graph-name">navigational / global positioning</div><br></br>
                  <div className="graph-name">space science / technology development</div><br></br>
                  <div className="graph-name">technology development / educational</div><br></br>
                </div>
                <div className="col-3">
                  <div className="graph d-flex flex-wrap text-center">
                    <div className="bg w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">Misaligned Inclination</div>
                      <div className="w-50 data py-3">Corrected Inclination</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">96.70</div>
                      <div className="w-50 data py-3">100.70</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">85.03</div>
                      <div className="w-50 data py-3">89.03</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">85.30</div>
                      <div className="w-50 data py-3">89.30</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">51.60</div>
                      <div className="w-50 data py-3">55.60</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">51.60</div>
                      <div className="w-50 data py-3">55.60</div>
                    </div>
                    <div className="w-100 d-flex border-bottom">
                      <div className="w-50 data py-3 border-right">64.78</div>
                      <div className="w-50 data py-3">68.78</div>
                    </div>
                    <div className="w-100 d-flex">
                      <div className="w-50 data py-3 border-right">97.98</div>
                      <div className="w-50 data py-3">101.98</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >);
  }
}

export default RepositionGate;