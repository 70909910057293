import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Hint from './../PageContainer/Hint/Hint';
import Note from './../PageContainer/Note/Note';
import store from '../../Redux/Store';
import { toggleNotes,updateClueCount , markToolTipMsgAsRead , closeToolTipSection, closeVideoSection, closeFileSection, closeProfileSection , openToolTipSection} from "./../../Redux/Modules/Tasks";
import { addHint } from "./../../Redux/Modules/Hint";
import Timer from "react-compound-timer";
import {HintAPI} from "../../Providers/Api";
import CustomImg from '../Global/CustomImg/CustomImg';

class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowHint: false,
      isShowNote: false,
      pathname: "/",
      hintReducer: {},
      date: null,     
      tasks:{},
      currentTask:{}
    }
  }
  
  static getDerivedStateFromProps(props, state) {
 
    //this.setState({tasks:props.tasks});
    if (props.location.pathname !== state.pathname) {
      return {
        isShowHint: false,
        isShowNote: false,
        pathname: props.location.pathname
      };
    }
    if (props.tasks !== state.tasks) {
      return {
        tasks: props.tasks
      };
    }
  
    return null;
  }

  showNote = () => {
    this.props.closeVideoSection();
    this.props.closeFileSection();
    this.props.closeProfileSection();

    if(!this.state.isShowNote)
    {
      this.props.openToolTipSection();
    }else{
      this.props.closeToolTipSection();
    }

    const taskReducer = store.getState().TaskReducer.isGameFinish;
    if (!taskReducer.isGameFinish) {
      this.setState({
        isShowNote: !this.state.isShowNote,
        isShowHint: false,
        currentTask: taskReducer.CurrentTask
      });
    }
  }

  showHint = () => {
    this.props.closeVideoSection();
    this.props.closeFileSection();
    this.props.closeProfileSection();
    if(!this.state.isShowHint)
    {
      this.props.openToolTipSection();
    }else{
      this.props.closeToolTipSection();
    }

    this.setState({ isShowHint: !this.state.isShowHint, isShowNote: false });
    //const isDashboardActive = store.getState().TaskReducer.isDashboardActive;     
    //if(isDashboardActive ) { 
    // if (this.props.TaskReducer.clue_remaining > 0) {
    //   this.setState({ isShowHint: !this.state.isShowHint, isShowNote: false });
    // } else {
    //   if (this.props.TaskReducer.clue_remaining === 0) {
    //     this.setState({ isShowHint: false });
    //   } else {
    //     this.setState({ isShowHint: !this.state.isShowHint, isShowNote: false });
    //   }
    // }
    //}
  };

  addHint =async ()=>{
    let res =await HintAPI.UpdateRemaininghintCount();
    if(res)
    {
      this.props.addHints();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            "hint-holder" +
            (this.state.isShowHint || this.state.isShowNote && this.props.isToolTipModal 
              ? " left-space"
              : " ")
          }
        >
          <div className="hint-module">
            <div className="hint-moduleBox">
            <button
                className={this.state.isShowNote ? "settingBox text-center active" : "settingBox text-center"}
                onClick={this.showNote}
              >
                <div>
                  <CustomImg src="setting-icon.svg" className="" alt="Setting Icon" />
                  <div className="title">Alteryx Tips</div>
                </div>
              </button>
             
              <div className={this.state.isShowHint ? "hintBox active" : "hintBox"} onClick={this.showHint}>
                <button className="hintBox-circle">
                  <span className="hintBox-circle-full">
                    <CustomImg width="100%" src="explanation-icon.svg" className="" alt="explanation-icon" />
                  </span>
                </button>
              </div>
            </div>
            <div className="top-handle"></div>
            <div className="bottom-handle"></div>
          </div>
          {this.state.isShowHint && this.props.isToolTipModal  && <Hint ShowHint={this.showHint} />}
          {this.state.isShowNote && this.props.isToolTipModal && <Note ShowNote={this.showNote} tasks={this.state.tasks} currentTask={this.state.currentTask} />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ HintReducer , TaskReducer }) => {
  return {             
    tasks : TaskReducer.tasks,
    isMsgSection : TaskReducer.isMsgSection,
    isToolTipModal : TaskReducer.isToolTipModal,
    HintReducer,
    TaskReducer
  };
};

const mapDispatchToProps = dispatch => ({
  addHints: () => dispatch(updateClueCount()),
  toggleNotes: () => dispatch(toggleNotes()),
  markToolTipMsgAsRead: () => dispatch(markToolTipMsgAsRead()),
  closeVideoSection: () => dispatch(closeVideoSection()),
  closeFileSection: () => dispatch(closeFileSection()),
  closeProfileSection: () => dispatch(closeProfileSection()),
  openToolTipSection: () => dispatch(openToolTipSection()),
  closeToolTipSection: () => dispatch(closeToolTipSection()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar));
