import { connect } from 'react-redux';
import { getAllMessages } from '../../../Redux/Modules/Message';
import Message from '../../../Components/PageContainer/Message/Message';

const mapStateToProps = ({ MessageReducer, TaskReducer }) => {
    return {
                messages : MessageReducer.data,
                CurrentTask : TaskReducer.CurrentTask,
                isExperienceGameFinished : TaskReducer.isExperienceGameFinished,
                isGameFinish : TaskReducer.isGameFinish
            };
};

const mapDispatchToProps = dispatch => ({
    getAllMessages: (tid) => dispatch(getAllMessages(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);