import aicc_api from './aicc-api';

import Http from '../Services/Http'
//  './Services/Http';
import Storage from './Storage';
import store from '../Redux/Store'
import history from './History';

import Cryptr  from 'cryptr';

const cryptr = new Cryptr('authsecret');

//const api = new Http({ host: config.api.baseUrl });
//const api = new Http({ host: "https://api-stag.theescapegamecourses.com:8083/api/v1/" });
const api = new Http({ host: process.env.REACT_APP_API });

api.getHeaders = () => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    const accessToken = Storage.get('access_token');
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

    return headers;
};

api.parseJsonResponse = json => {
 //   console.log("Parse Json response API ", json);
    if (typeof json === 'undefined' && !json.token) {
        //if(!store.getState().Auth.hasExpired) {
        throw new Error("No data member specified on API response.");
        //} else {
        json.data = 0;
        //}
    }

    if (json && json.token) {
        // cant import this functionality from auth redux module due to its dependencies
        // on AuthApi being defined here
        Storage.set("access_token", json.token);
        store.dispatch({ type: "teg/SET_ACCESS_TOKEN", accessToken: json.token });
    }

    return json;
};

api.parseJsonError = (json, status) => {
    if (status === 401) {
        const curState = store.getState();
        if (curState.Auth.isAuthenticated || Storage.get('access_token')) {
            // cant import this functionality from auth redux module due to its dependencies
            // on AuthApi being defined here
            store.dispatch({ type: "teg/UNSET_ACCESS_TOKEN" });
            //store.dispatch({ type: "teg/SESSION_EXPIRED" });
            Storage.remove("access_token");
        }
    }
    throw { ...json.errors, status: status };
};

export default api;


export const MessageApi = {
    markMessageAsRead: async (msgId) => { 
        let res = await api.post(`task/${msgId}/progress`,{"type":"MESSAGE"});  
        return res;
    }
}

export const VideoApi = {
    markVideoAsRead: async (msgId) => {
        let res = await api.post(`task/${msgId}/progress`,{"type":"VIDEO"});  
        return res;
    },
}

export const UserApi = {
    updateUserName: async (name) => {
        let res = await api.put('user/profile',{"nickname":name});  
        return res;
    }
}

export const TaskApi = {
    getEnrolled: async () => {
        try{ 
            aicc_api.init();
        }catch(e) {
            
            history.push('/unauthorized');
        }
        let StudentName = aicc_api.getStudentName();
        let StudentId =  aicc_api.getStudentId();
        let lms_url = aicc_api.getAiccUrlLms();
        let data = { "lms_id": 11231319222239221, "experience_id": 1, username: StudentName , lms_url:lms_url}
        let res = await api.post('enrolled', data);
        return res;
    },
    getAllTasks: async () => {
        aicc_api.init();
        let tasks = await api.get('tasks/detail');  
        for (let task in tasks) {
            if(tasks[task].Solutions && tasks[task].Solutions.length){
                for (let solution in tasks[task].Solutions) {
                    tasks[task].Solutions[solution].answer_key = (tasks[task].Solutions[solution].answer_key) ?  JSON.parse(cryptr.decrypt(tasks[task].Solutions[solution].answer_key)) :[];
                }
            }
        }          
        return tasks;
    },
    getTaskById: (id) => {
        let res = api.get(`tasks/detail/${id}`);
        return res; //task.filter((data,key)=> { return key==id; });
    },
    markAsCompleted: async (id,postData) => {
        postData = {"is_correct":true,"response":""}
        let res = await api.post(`task/${id}/addresponse`,postData);        
        aicc_api.setScore(res.score);
        return res;
        
    },
    markLastActivity:  async () => {
        let res = await api.put(`enrollment/lastactivity`,{},false);
        //console.log("Mark As completed score ", res.data.total_time);        
        let time =sec2time(res.data.total_time);
        //console.log("Mark Last Acctivity ",time,res.data.total_time);
        aicc_api.setTime(time);
    },
    markExperienceEnd : async () => {     
        let res;
        res = await api.post(`enrollment/endtime`, {markExperienceCompleted:true});        
        aicc_api.setCompleted();    
        return res; 
    },
    markExperienceCompleted: async () => {       
      
       // let res = await api.put(`enrollment/lastactivity`);
        //console.log("Mark As completed score ", res.data.total_time);        
        let res;
       /* let time =sec2time(res.data.total_time);
        console.log("Mark Last Acctivity ",time,res.data.total_time);
        aicc_api.setTime(time);
*/
/*console.log("Deepti Here ");
        res = await api.post(`enrollment/endtime`, {markExperienceCompleted:true});        
        aicc_api.setCompleted();*/
     //   return res;
       
    },
    markExperienceAsRead: async () => {
        //API call
    }
}

export const HintAPI = {
    AddUserClueRequest : async (clueid) =>{
        let res = await api.post(`clue/${clueid}/request`);
        
        return res;
    },
    UpdateRemaininghintCount : async ()=>{
        let res = await api.put(`clue/count`,{"count":1});       
        return res;
    }
}

function  sec2time(timeInSeconds) {
    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
}