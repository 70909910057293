import React, { Component } from "react";
import { connect } from "react-redux";
import { task } from "../../../data/data";
import Modal from "./../../Global/Modal/Modal";
import { useHint } from "./../../../Redux/Modules/Hint";
import { updateScore,updateClueRequest,subtractHint } from "./../../../Redux/Modules/Tasks";
import { HintAPI } from "../../../Providers/Api";
import CustomImg from '../../Global/CustomImg/CustomImg';

class TaskComplexity extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      point: 0,
      selectedType: "",
      tasklevel:["Easy", "Medium", "Hard", "Answer"]
    };
  }

//   componentDidMount() {
    
//   }
  showWarning = type => {

    
    let point = 0;
    // if (type === "Easy") {
    //   point = 2;
    // } else if (type === "Medium") {
    //   point = 5;
    // } else if (type === "Hard") {
    //   point = 10;
    // } else {
    //   point = 13;
    // }
    this.setState({
      modal: !this.state.modal,
      point: type[0].point,
      selectedType: type[0].clue_type
    });
  };
  cancelPopUp = ()=>{
    this.setState({
      modal: !this.state.modal,
      
    });
  }
  ConfirmUseHint = async () => {
    let clue = this.props.TaskReducer.CurrentTask.Clues.filter(
      x => x.clue_type.toUpperCase() === this.state.selectedType.toUpperCase()
    );
    if (clue.length > 0) {
      let res = await HintAPI.AddUserClueRequest(clue[0].clue_id);
      if(res){
        this.props.showFinalHint(res.clue);
        this.props.updateScore(res.score);
        //this.props.updateClueRequest(res.clueRequest);
        this.props.useHints();
      }
    }
    // this.props.useHints();
    // this.props.showFinalHint();
  };
  render() {
    return (
      <React.Fragment>
        <div
          id="what-label-box"
          className={
            "step-two " + this.props.showTaskHintComplex ? "d-block" : "d-none"
          }
        >
          <button
            className="back-arrow"
            onClick={() => this.props.showTaskComplexity()}
          >
            <CustomImg src="gradiant-close.svg" className="img-close" alt="img-close" />
          </button>
          <div className="contentHint">
            <h1>
              <CustomImg src="gradiant-explanation-icon.svg" className="img-fluid" alt="gradiant-explanation" />
              what level of hint do you need?
            </h1>
            <div className="w100 mt-5 text-center">
              {this.state.tasklevel.map((data, key) => {
                   let clueTypeFilter = this.props.TaskReducer.CurrentTask.Clues.filter(
                            x => x.clue_type.toUpperCase() === data.toUpperCase())
                            const classColor = (key === 0 ? "easy-btn" : data.toLowerCase() + "-btn");
                return <button
                  key={key}
                  className="red-btn"
                  onClick={() => this.showWarning(clueTypeFilter)}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  <div className={"redcolor " + classColor}>
                    <span>{data}</span>
                  </div>
                </button>
              })}
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          modalClass={"modal fade sm-popup-hint"}
          dialogClass={"modal-dialog modal-dialog-centered"}
        >
          <div className="modal-body p-5 text-center contentHint">
            <button
              onClick={this.cancelPopUp}
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
                 <CustomImg src="gradiant-close.svg" className="" alt="gradiant-close" />
            </button>
            <div className="w-25 mx-auto">
              <CustomImg src="gradiant-explanation-icon.svg" className="img-fluid" alt="gradiant-explanation-icon" />
            </div>
            <div className="bigtxt">are you sure?</div>
            <div className="smtxt">
              using this hint will deduct
              <br />
              <span className="point-txt">{this.state.point} points</span> from
              your score!
            </div>
            <div className="clearfix"></div>
            <div className="buttonBox">
              <button
                onClick={this.ConfirmUseHint}
                className="red-btn"
                id="sure-btn"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                <div className="redcolor iamsure-btn">
                  <span>yes, I’m sure!</span>
                </div>
              </button>
              <button
                className="red-btn"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                <div
                  className="redcolor cancel-btn"
                  onClick={this.cancelPopUp}
                >
                  <span>nope, cancel!</span>
                </div>
              </button>
            </div>
            <div className="clearfix"></div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = ({ HintReducer, TaskReducer }) => {
  return { HintReducer, TaskReducer };
};

const mapDispatchToProps = dispatch => ({
  updateScore:(score)=>dispatch(updateScore(score)),
  updateClueRequest:(data)=>dispatch(updateClueRequest(data)),
  useHints: () => dispatch(subtractHint())
});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(TaskComplexity);
