import React, { Component } from 'react';
import CustomImg from './../Global/CustomImg/CustomImg';
class NotFound extends Component {
    render() {
        return (<React.Fragment>
            <div className="d-flex justify-content-center align-items-center vh-100 text-white">
                <div className="text-center"><CustomImg src="umrela-img.png" className=" mb-5" alt="Authorized" />
                <h1>You are not Authorized.</h1>
                </div>
            </div>
        </React.Fragment>);
    }
}

export default NotFound;