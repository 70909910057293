import React, { Component } from 'react';
import '././../../../../Styles/AccessThreat.scss';
import CustomImg from '../../../Global/CustomImg/CustomImg';

class AccessThreat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: [],
            Satellites: [{ id: "1", satelliteName: "Satellite One", position: "rowOne", popupplacement: "left", satelliteNormalImage: "AssessThreatB-one.svg", satelliteHoverImage: "AssessThreatB-one-hover.svg", satellitegreenImage: "AssessThreatB-one-green.svg", satelliteType: '', threatLevel: '', clustor: '5' },
            { id: "2", satelliteName: "Satellite Two", position: "rowOne", popupplacement: "left", satelliteNormalImage: "AssessThreatB-two.svg", satelliteHoverImage: "AssessThreatB-two-hover.svg", satellitegreenImage: "AssessThreatB-two-green.svg", satelliteType: '', threatLevel: '', clustor: '5' },
            { id: "3", satelliteName: "Satellite Three", position: "rowOne", popupplacement: "right", satelliteNormalImage: "AssessThreatB-three.svg", satelliteHoverImage: "AssessThreatB-three-hover.svg", satellitegreenImage: "AssessThreatB-three-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false },
            { id: "4", satelliteName: "Satellite Four", position: "rowOne", popupplacement: "right", satelliteNormalImage: "AssessThreatB-four.svg", satelliteHoverImage: "AssessThreatB-four-hover.svg", satellitegreenImage: "AssessThreatB-four-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false },
            { id: "5", satelliteName: "Satellite Five", position: "rowTwo", popupplacement: "left-bottom", satelliteNormalImage: "AssessThreatB-five.svg", satelliteHoverImage: "AssessThreatB-five-hover.svg", satellitegreenImage: "AssessThreatB-five-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false },
            { id: "6", satelliteName: "Satellite Six", position: "rowTwo", popupplacement: "left-bottom", satelliteNormalImage: "AssessThreatB-six.svg", satelliteHoverImage: "AssessThreatB-six-hover.svg", satellitegreenImage: "AssessThreatB-six-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false },
            { id: "7", satelliteName: "Satellite Seven", position: "rowTwo", popupplacement: "right-bottom", satelliteNormalImage: "AssessThreatB-saven.svg", satelliteHoverImage: "AssessThreatB-saven-hover.svg", satellitegreenImage: "AssessThreatB-saven-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false },
            { id: "8", satelliteName: "Satellite Eight", position: "rowTwo", popupplacement: "right-bottom", satelliteNormalImage: "AssessThreatB-eate.svg", satelliteHoverImage: "AssessThreatB-eate-hover.svg", satellitegreenImage: "AssessThreatB-eate-green.svg", satelliteType: '', threatLevel: '', clustor: ' ', isComplete: false }],
            AnswerSheet: [],
            SelectedSatellite: 0,
            taskId: 0,
            CurrentTask: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                }
            }
        }
        return null;
    }

    handleClick = (e, index) => {
        e.stopPropagation();
        const currentObjectValue = this.state.progress.filter(m => { return m.access_threat_id === index })[0];
        if (currentObjectValue !== undefined && index !== 0) {
            if (!currentObjectValue.is_correct) {
                this.setState({
                    SelectedSatellite: index
                });
            }
        } else {
            this.setState({
                SelectedSatellite: index
            });
        }
    }

    handleChange = async (threatId, value, isSelect) => {
        const threatLevel = this.refs["txtBoxThreatLevel" + threatId].value;
        const clustor = this.refs["txtBoxclustor" + threatId].value;
        let prevValue = this.refs["satellite_type" + threatId].value;
        if (prevValue.length === 0) {
            prevValue = "SELECT SATELLITE TYPE";
        }
        const satelliteType = isSelect ? value : prevValue;
        let is_correct = false;
        const newPregress = this.state.progress;
        const currentSatellite = this.state.Satellites.filter(m => { return m.id === threatId })[0];
        const answers = this.state.AnswerSheet[0].answer_key;
        const newAnswers = answers.map((data, index) => {
            if (data.ID.toLowerCase() === currentSatellite.id.toLowerCase()) {
                if (data.PURPOSE.trim().toLowerCase() === satelliteType.trim().toLowerCase() && data.LEVEL.trim() === threatLevel.trim() && data.SATCOUNT.trim() === clustor.trim()) {
                    is_correct = true;
                }
            }
            return data;
        });

        this.state.AnswerSheet[0].answer_key = newAnswers;

        this.setState({
            AnswerSheet: this.state.AnswerSheet
        });

        const progressValue = newPregress.filter(m => { return m.access_threat_id === threatId })[0];
        if (progressValue) {
            progressValue.satellite_type = satelliteType;
            progressValue.threat_level = threatLevel;
            progressValue.clustor = clustor;
            progressValue.is_correct = is_correct;

        } else {
            const newThreat = {
                access_threat_id: threatId,
                satellite_name: currentSatellite.satelliteName,
                satellite_type: satelliteType,
                threat_level: threatLevel,
                clustor: clustor,
                is_correct: is_correct
            };
            newPregress.push(newThreat);
        }

        this.setState({
            progress: newPregress
        });

        //API call 
        const countCompletedTasks = this.state.progress.filter(m => m.is_correct === true);
        if (countCompletedTasks.length === 8) {
            this.setState({
                SelectedSatellite: 0
            });
            await this.props.markAsCompleted(this.state.taskId);
        }
    }

    selectValue = (index, value) => {
        this.handleChange(index, value, true);
    }

    render() {
        return (
            <React.Fragment>
                <div id="container">
                    <div className="page_content">
                        <div className={"videos-holder assess-threat-holder " + (this.state.SelectedSatellite > 0 ? 'show-overlay' : '')} id="assess-threat">

                            <div className="col h-100">
                                +                           <div className="row d-flex align-items-center h-50">
                                    {this.state.Satellites.filter((data) => data.position == "rowOne").map((data) => {
                                        const currentObjectValue = this.state.progress.filter(m => { return m.access_threat_id === data.id })[0];
                                        return (<div className="col" key={data.id}>
                                            <button onClick={(event) => this.handleClick(event, data.id)} className={"assessItem" + (this.state.SelectedSatellite === data.id ? ' active' : '') + ((currentObjectValue && currentObjectValue.is_correct) ? ' completed' : '')} type="button" popup-placement={data.popupplacement}>
                                                <CustomImg src={data.satelliteNormalImage} className="img-normal" alt="img-normal" />
                                                <CustomImg src={data.satelliteHoverImage} className="img-hover-active" alt="img-hover-active" />
                                                <CustomImg src={data.satellitegreenImage} className="img-green" alt="img-green" />
                                                <div className="pupupBox">
                                                    <div className="popup-inner-content">
                                                        <a onClick={(event) => this.handleClick(event, 0)} className="close-popup assess-close-btn" href="javascript:void(0)">
                                                            <em className="icon-close"></em>
                                                        </a>
                                                        <div className="content">
                                                            <h4>{data.satelliteName}</h4>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"threat level"  + data.id}>threat level</label>
                                                                        <div className="input-outer"><input ref={"txtBoxThreatLevel" + data.id} value={(currentObjectValue) ? currentObjectValue.threat_level : ""} onChange={() => this.handleChange(data.id, 0, 0)} type="text" className="form-control" id={"threat level"  + data.id} name="threatLevel"
                                                                            aria-describedby="emailHelp" /></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"count"  + data.id}>count in cluster</label>
                                                                        <div className="input-outer"><input ref={"txtBoxclustor" + data.id} value={(currentObjectValue) ? currentObjectValue.clustor : ""} onChange={() => this.handleChange(data.id, 0, 0)} type="text" className="form-control" id={"count"  + data.id} name="clustor" /></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"satellite_type" + data.id}>satellite type</label>
                                                                        <div className="input-outer">
                                                                            <div className="dropdown select-dropdown-menu">
                                                                                <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    {(currentObjectValue) ? currentObjectValue.satellite_type : "SELECT SATELLITE TYPE"}
                                                                                </button>
                                                                                <input id={"satellite_type" + data.id} type="hidden" ref={"satellite_type" + data.id} value={(currentObjectValue) ? currentObjectValue.satellite_type : ""} />
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Maritime Tracking")} >Communications Maritime Tracking</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Financial")} >Communications Financial</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Civil")} >Communications Civil</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Earth Observation/Earth Science/Weather")} >Earth Observation/Earth Science/Weather</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Navigation/Global Positioning")} >Navigation/Global Positioning</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Technology Development/Educational")} >Technology Development/Educational</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Space Science/Technology Development")} >Space Science/Technology Development</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Surveillance")} >Surveillance</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>)
                                    })}
                                </div>
                                <div className="row d-flex align-items-center  h-50">
                                    {this.state.Satellites.filter((data) => data.position == "rowTwo").map((data) => {
                                        const currentObjectValue = this.state.progress.filter(m => { return m.access_threat_id === data.id })[0];
                                        return (<div className="col" key={data.id}>
                                            <button onClick={(event) => this.handleClick(event, data.id)} className={"assessItem " + (this.state.SelectedSatellite === data.id ? 'active' : '') + ((currentObjectValue && currentObjectValue.is_correct) ? ' completed' : '')} type="button" popup-placement={data.popupplacement}>
                                                <CustomImg src={data.satelliteNormalImage} className="img-normal" alt="img-normal" />
                                                <CustomImg src={data.satelliteHoverImage} className="img-hover-active" alt="img-hover-active" />
                                                <CustomImg src={data.satellitegreenImage} className="img-green" alt="img-green" />
                                                <div className="pupupBox">
                                                    <div className="popup-inner-content">
                                                        <a onClick={(event) => this.handleClick(event, 0)} className="close-popup assess-close-btn" href="javascript:void(0)">
                                                            <em className="icon-close"></em>
                                                        </a>
                                                        <div className="content">
                                                            <h4>{data.satelliteName}</h4>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"threat level"  + data.id}>threat level</label>
                                                                        <div className="input-outer"><input ref={"txtBoxThreatLevel" + data.id} value={(currentObjectValue) ? currentObjectValue.threat_level : ""} onChange={() => this.handleChange(data.id, 0, 0)} type="text" className="form-control" id={"threat level"  + data.id} name="threatLevel"
                                                                            aria-describedby="emailHelp" /></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"count"  + data.id}>count in cluster</label>
                                                                        <div className="input-outer"><input ref={"txtBoxclustor" + data.id} value={(currentObjectValue) ? currentObjectValue.clustor : ""} onChange={() => this.handleChange(data.id, 0, 0)} type="text" className="form-control" id={"count"  + data.id} name="clustor" /></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"satellite_type"  + data.id}>satellite type</label>
                                                                        <div className="input-outer">
                                                                            <div className="dropdown select-dropdown-menu">
                                                                                <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    {(currentObjectValue) ? currentObjectValue.satellite_type : "SELECT SATELLITE TYPE"}
                                                                                </button>
                                                                                <input type="hidden" ref={"satellite_type" + data.id} value={(currentObjectValue) ? currentObjectValue.satellite_type : ""} />
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Maritime Tracking")} >Communications Maritime Tracking</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Financial")} >Communications Financial</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Communications Civil")} >Communications Civil</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Earth Observation/Earth Science/Weather")} >Earth Observation/Earth Science/Weather</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Navigation/Global Positioning")} >Navigation/Global Positioning</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Technology Development/Educational")} >Technology Development/Educational</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Space Science/Technology Development")} >Space Science/Technology Development</li>
                                                                                    <li onClick={() => this.selectValue(data.id, "Surveillance")} >Surveillance</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div className="overlay-background" style={{ height: this.state.OverlayHeight }}></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AccessThreat;