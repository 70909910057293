import React, { Component } from "react";
import "././../../../../Styles/SatellitePositions.scss";
import { clear } from "sisteransi";
import CustomImg from '../../../Global/CustomImg/CustomImg';

class SatellitePositions extends Component {
  state = {
    showButtons: false,
    disks: [
      { id: 1, rotation: 0, imageName: "dish-blue.png", height: 380 },
      { id: 2, rotation: 0, imageName: "red-dish.png", height: 420 },
      { id: 3, rotation: 0, imageName: "dish-blue.png", height: 525 },
      { id: 4, rotation: 0, imageName: "red-dish.png", height: 900 }
    ],
    skySatelliteRotation: 0,
    selectedDisk: 0,
    showLaser1: false,
    showLaser2: false,
    showLaser3: false,
    showLaser4: false,
    taskCompleted: false,
    refresh: false,
    // AnswerSheet: [
    //   {
    //     answer_key: {
    //       disks: [
    //         {
    //           minAngle: 55,
    //           maxAngle: 57
    //         },
    //         {
    //           minAngle: 15,
    //           maxAngle: 17
    //         },
    //         {
    //           minAngle: -13,
    //           maxAngle: -11
    //         },
    //         {
    //           minAngle: -59,
    //           maxAngle: -57
    //         }
    //       ],
          // topSatellite: {
          //   minAngle: 132,
          //   maxAngle: 141
          // }
    //     }
    //   }
    // ],
    AnswerSheet:[],
    taskId: 0,
    showReestablishMessage: false,
    CurrentTask: {}
  };

  static getDerivedStateFromProps(props, state) {
    //console.log(props.CurrentTask,state.CurrentTask)
    if (props.CurrentTask !== state.CurrentTask) {
      let currentTask = props.CurrentTask;
      let currentTaskSolutions = currentTask.Solutions;
      if (currentTask.Solutions !== undefined) {
        return {
          AnswerSheet: currentTaskSolutions,
          taskId: currentTask.id
        };
      }
    }
    return null;
  }

  enableButtons = index => {
    
  
    this.setState({
      showButtons: true,
      selectedDisk: index
    });
  };

  showLaser = () => {
    
      if (this.state.selectedDisk !== 5) {
        this.setState({
          ["showLaser" + this.state.selectedDisk]: true
        });
        var obj = this.state.disks.filter(
          x => x.id === this.state.selectedDisk
        )[0];
        //console.log("OBJECT", obj, this.state);
        if (this.state.selectedDisk === 1) {
          if (
            obj.rotation < 55 ||
            obj.rotation > 57 ||
            (this.state.skySatelliteRotation <= 132 ||
              this.state.skySatelliteRotation >= 141)
          ) {
            setTimeout(() => {
              this.setState({
                ["showLaser" + this.state.selectedDisk]: false
              });
            }, 200);
          }
        } else if (this.state.selectedDisk === 2) {
          if (
            obj.rotation < 15 ||
            obj.rotation > 17 ||
            (this.state.skySatelliteRotation <= 132 ||
              this.state.skySatelliteRotation >= 141)
          ) {
            setTimeout(() => {
              this.setState({
                ["showLaser" + this.state.selectedDisk]: false
              });
            }, 200);
          }
        } else if (this.state.selectedDisk === 3) {
          if (
            obj.rotation < -13 ||
            obj.rotation > -11 ||
            (this.state.skySatelliteRotation <= 132 ||
              this.state.skySatelliteRotation >= 141)
          ) {
            setTimeout(() => {
              this.setState({
                ["showLaser" + this.state.selectedDisk]: false
              });
            }, 200);
          }
        } else if (this.state.selectedDisk === 4) {
          if (
            obj.rotation < -59 ||
            obj.rotation > -57 ||
            (this.state.skySatelliteRotation <= 132 ||
              this.state.skySatelliteRotation >= 141)
          ) {
            setTimeout(() => {
              this.setState({
                ["showLaser" + this.state.selectedDisk]: false
              });
            }, 200);
          }
        }
      }
      this.setState(
        state => {
          return { refresh: !state.refresh };
        },
        () => {
          this.checkpuzzlecorrectnes();
        }
      );
    
  };

  leftRotation = () => {
    if (this.state.selectedDisk > 0) {
      if (
        this.state.selectedDisk === 5 &&
        (!this.state.showLaser1 &&
          !this.state.showLaser2 &&
          !this.state.showLaser3 &&
          !this.state.showLaser4)
      ) {
        const leftRotation = parseInt(this.state.skySatelliteRotation) - 10;
        //console.log(leftRotation)
        if (leftRotation >= 0){
          this.setState(
            {
              skySatelliteRotation: leftRotation
            },
            () => {
              this.checkpuzzlecorrectnes();
            }
          );
        }
        else{
          clearInterval(this.startleftrotationinterval)
        }
      } else {
        if (this.state.selectedDisk !== 5) {
          const currentObject = this.state.disks.filter(
            m => m.id === this.state.selectedDisk
          )[0];
          if (!this.state["showLaser" + this.state.selectedDisk]) {
            const leftRotation = parseInt(currentObject.rotation) - 2;
            if (leftRotation > -100) {
              const updatedDiskArray = this.state.disks.map(el =>
                el.id === this.state.selectedDisk
                  ? { ...el, rotation: leftRotation }
                  : el
              );
              this.setState(
                {
                  disks: updatedDiskArray
                },
                () => {
                  this.checkpuzzlecorrectnes();
                }
              );
            }
          }
        }
      }
    }
  };

  rightRotation = () => {
    if (this.state.selectedDisk > 0) {
      if (
        this.state.selectedDisk === 5 &&
        (!this.state.showLaser1 &&
          !this.state.showLaser2 &&
          !this.state.showLaser3 &&
          !this.state.showLaser4)
      ) {
        const rightRotation = parseInt(this.state.skySatelliteRotation) + 10;
        //console.log(rightRotation);
        if (rightRotation <= 360) {
          this.setState(
            {
              skySatelliteRotation: rightRotation
            },
            () => {
              this.checkpuzzlecorrectnes();
            }
          );
        }
        else{
          clearInterval(this.startrightrotationinterval)
        }
      } else {
        if (this.state.selectedDisk !== 5) {
          const currentObject = this.state.disks.filter(
            m => m.id === this.state.selectedDisk
          )[0];
          if (!this.state["showLaser" + this.state.selectedDisk]) {
            const rightRotation = parseInt(currentObject.rotation) + 2;
            if (rightRotation < 100) {
              const updatedDiskArray = this.state.disks.map(el =>
                el.id === this.state.selectedDisk
                  ? { ...el, rotation: rightRotation }
                  : el
              );
              this.setState(
                {
                  disks: updatedDiskArray
                },
                () => {
                   this.checkpuzzlecorrectnes();
                }
              );
            }
          }
        }
      }
    }
  };

  checkpuzzlecorrectnes() {
    const answers = this.state.AnswerSheet[0].answer_key;
    let isSatellite_in_positions = false;
    if (
      this.state.skySatelliteRotation >= answers.topSatellite.minAngle &&
      this.state.skySatelliteRotation <= answers.topSatellite.maxAngle
    )
      isSatellite_in_positions = true;
    else {
      isSatellite_in_positions = false;
    }

    if (isSatellite_in_positions) {
      this.state.disks.map(item => {
        if (
          item.id === 1 &&
          item.rotation >= answers.disks[0].minAngle &&
          item.rotation <= answers.disks[0].maxAngle
        )
          isSatellite_in_positions = !isSatellite_in_positions ? false : true;
        else if (
          item.id === 2 &&
          item.rotation >= answers.disks[1].minAngle &&
          item.rotation <= answers.disks[1].maxAngle
        )
          isSatellite_in_positions = !isSatellite_in_positions ? false : true;
        else if (
          item.id === 3 &&
          item.rotation >= answers.disks[2].minAngle &&
          item.rotation <= answers.disks[2].maxAngle
        ) {
          isSatellite_in_positions = !isSatellite_in_positions ? false : true;
        } else if (
          item.id === 4 &&
          item.rotation >= answers.disks[3].minAngle &&
          item.rotation <= answers.disks[3].maxAngle
        ) {
          isSatellite_in_positions = !isSatellite_in_positions ? false : true;
        } else {
          isSatellite_in_positions = false;
        }

        return isSatellite_in_positions;
      });

      
      if (
        isSatellite_in_positions &&
        this.state.showLaser1 &&
        this.state.showLaser2 &&
        this.state.showLaser3 &&
        this.state.showLaser4
      ) {
        console.log();
        this.setState({ taskCompleted: true, showReestablishMessage: true });
        console.log();
        setTimeout(() => {
          this.setState({
            showReestablishMessage: false
          });
          this.props.markAsCompleted(this.state.taskId);
        }, 4000);
      }
    }
  }

  startleftRotation = () => {
    this.startleftrotationinterval = setInterval(() => {
      this.leftRotation();
    }, 100);
  };

  stopleftRotation = () => {
    clearInterval(this.startleftrotationinterval);
  };

  startrightRotation = () => {
    this.startrightrotationinterval = setInterval(() => {
      this.rightRotation();
    }, 100);
  };

  stoprightRotation = () => {
    clearInterval(this.startrightrotationinterval);
  };

  render() {
    return (
      <React.Fragment>
        <div id="container">
          <div className="page_content">
            <div className="satelliteposition-holder">
              <div
                className={
                  this.state.showReestablishMessage
                    ? "satellitecontent overly"
                    : "satellitecontent"
                }
              >
                <div className="top-cloudBox">
                  <div
                    className="top-satellite"
                    onClick={() => this.enableButtons(5)}
                    style={{
                      transform: `rotate(${this.state.skySatelliteRotation}deg)`
                    }}
                  >
                    
                    <CustomImg src={
                        this.state.selectedDisk === 5
                          ? "top-satellite01-select.png"
                          : "top-satellite01.png"
                      }
                     className="" alt="top-satellite" />
                    />
                  </div>
                  <div className="mirror-box">
                    <div className="mirror-one">
                      <CustomImg src={
                          this.state.showLaser1 &&
                          (this.state.disks[0].rotation >=
                            this.state.AnswerSheet[0].answer_key.disks[0]
                              .minAngle &&
                            this.state.disks[0].rotation <=
                              this.state.AnswerSheet[0].answer_key.disks[0]
                                .maxAngle)
                            ? "puzzle-mirror-shadow.png"
                            : "puzzle-mirror.png"
                        } 
                     className=""  alt="mirror-one" />
                    </div>
                    <div className="mirror-two">

                    <CustomImg src={
                          this.state.showLaser1 &&
                          (this.state.disks[0].rotation >=
                            this.state.AnswerSheet[0].answer_key.disks[0]
                              .minAngle &&
                            this.state.disks[0].rotation <=
                              this.state.AnswerSheet[0].answer_key.disks[0]
                                .maxAngle)
                            ? "puzzle-mirror-shadow.png"
                            : "puzzle-mirror.png"
                        }
                     className=""  alt="mirror-one" />
                    </div>
                  </div>
                </div>
                {this.state.showReestablishMessage && (
                  <div className="connection-success-popup">
                    <div className="popupInner">
                      <div className="media-body">
                        <h5 className="text-white mb-4">
                          {"COMMUNICATION RE-ESTABLISHED"}
                        </h5>
                        <div className="d-flex justify-content-center">
                          <div className="signal-established-1 mx-1"></div>
                          <div className="signal-established-2 mx-1"></div>
                          <div className="signal-established-3 mx-1"></div>
                          <div className="signal-established-4 mx-1"></div>
                          <div className="signal-established-5 mx-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/*  <CustomImg src="s2.png" className="" alt="s2" />   */}
                <div className="bottom-bowl">
                  {this.state.disks.map(data => {
                    return (
                      <div
                        key={data.id}
                        className="dishOne dishBox"
                        onClick={() => this.enableButtons(data.id)}
                      >
                        <div
                          className="top-part"
                          style={{ transform: `rotate(${data.rotation}deg)` }}
                        >
                          <CustomImg src={data.imageName} className="" alt="top-part" />
                          <div
                            className={
                              "laser-box-" +
                              data.id +
                              " " +
                              (this.state["showLaser" + data.id]
                                ? "activelaser"
                                : "")
                            }
                          >
                            {data.id === 1 &&
                              (data.rotation >= 56 && data.rotation <= 58) && (
                                <svg height="900" width="145">
                                  <polyline
                                    points="-30,1000 260,-100 360,-800 720,-2000"
                                    className="cls-1-1"
                                  />
                                  <polyline
                                    points="0,330 800,350"
                                    className="cls-1-1"
                                  />
                                  {/* <line x1="-25" y1="325" x2="300" y2="300" className="cls-1-1"></line> */}
                                </svg>
                              )}

                            <svg
                              id="Layer_1"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox={data.id === 4 ? "0 0 4.24 508.14" : ""}
                              height={data.height}
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  x1="4332.28"
                                  y1="-1892.58"
                                  x2="4332.28"
                                  y2="-2400.71"
                                  gradientTransform="translate(-4330.21 2400.6)"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop offset="0" stopColor="#5380c1" />
                                  <stop offset="0.09" stopColor="#5b94c8" />
                                  <stop offset="0.27" stopColor="#6ec8db" />
                                  <stop offset="0.29" stopColor="#70cddd" />
                                  <stop offset="0.38" stopColor="#6abed8" />
                                  <stop offset="0.55" stopColor="#5c97c9" />
                                  <stop offset="0.64" stopColor="#5380c1" />
                                  <stop offset="0.64" stopColor="#5484c2" />
                                  <stop offset="0.71" stopColor="#60a4ce" />
                                  <stop offset="0.78" stopColor="#69bbd6" />
                                  <stop offset="0.84" stopColor="#6ec8db" />
                                  <stop offset="0.9" stopColor="#70cddd" />
                                  <stop offset="0.71" stopColor="#60a4ce" />
                                  <stop offset="0.78" stopColor="#69bbd6" />
                                  <stop offset="0.84" stopColor="#6ec8db" />
                                  <stop offset="0" stopColor="#5380c1" />
                                </linearGradient>
                              </defs>
                              <title>Untitled-9</title>
                              {data.id === 1 && (
                                <line
                                  className={"cls-1-" + data.id}
                                  x1="2.12"
                                  y1="508.14"
                                  x2="2.13"
                                />
                              )}
                              {data.id === 2 && (
                                <line
                                  className={"cls-1-" + data.id}
                                  x1="2.12"
                                  y1="508.14"
                                  x2="2.13"
                                  y2={data.id === 2 ? "-220" : ""}
                                />
                              )}
                              {data.id === 3 && (
                                <line
                                  className={"cls-1-" + data.id}
                                  x1="2.12"
                                  y1="508.14"
                                  x2="2.13"
                                />
                              )}
                              {data.id === 4 && (
                                <line
                                  className={"cls-1-" + data.id}
                                  x1="2.12"
                                  y1="508.14"
                                  x2="2.13"
                                />
                              )}
                            </svg>
                          </div>
                        </div>
                        <div
                          className={
                            "bottom-part " +
                            (this.state.selectedDisk === data.id ||
                            this.state["showLaser" + data.id]
                              ? "active"
                              : "")
                          }
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={
                  "satellite-position-buttons" +
                  (this.state.showButtons ? " active" : "")
                }
              >
                <div className="d-flex">
                  <button
                    className="btn-previous btn"
                    onMouseDown={this.startleftRotation}
                    onMouseUp={this.stopleftRotation}
                    onClick={this.leftRotation}
                    type="button"
                  >
                    <CustomImg src="left-arrow-satellite.png" className="" alt="btn-previous" />
                  </button>
                  <button onClick={this.showLaser} className="btn point btn">
                   <CustomImg src="point-icon.png" className="" alt="btn point btn" />
                  </button>
                  <button
                    className="btn-next btn"
                    onClick={this.rightRotation}
                    onMouseDown={this.startrightRotation}
                    onMouseUp={this.stoprightRotation}
                    type="button"
                  >
                    <CustomImg src="right-arrow-satellite.png" className="" alt="btn-next btn" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SatellitePositions;
