import React, { Component } from 'react';
import './../../../../Styles/FormulaWindow.scss';
import CustomImg from '../../../Global/CustomImg/CustomImg';

class FormulaWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AnswerSheet: [],
      taskId: 0,
      formula: "",
      CurrentTask : {}
    };
    this.contentBox = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.CurrentTask !== state.CurrentTask) {
      let currentTask = props.CurrentTask;
      let currentTaskSolutions = currentTask.Solutions;
        if (currentTask.Solutions !== undefined) {
            return {
                AnswerSheet: currentTaskSolutions,
                taskId: currentTask.id,
            }
        }
    }
    return null;
  }

  setFormula = (formula) => {
    this.setState({
      formula: formula
    });
    this.refs["custom-textarea"].value = this.refs["custom-textarea"].value + formula;
  }

  evaluateAnswer = async () => {
    const answers = this.state.AnswerSheet;
    const formulaContainer = this.refs["custom-textarea"].value;

    let isCorrect = false;
    if (this.state.formula.toLowerCase() === this.refs["sampleAnswer"].value.toLowerCase()) {
      answers.map((data) => {
        const result = data.answer_key[0].FORMULA;
        if (result.toLowerCase().replace(/ /g, '') === formulaContainer.trim().toLowerCase().replace(/ /g, '')) {
          isCorrect = true;
        }
      });
    }

    if (isCorrect) {
      setTimeout(async () => {
        await this.props.markAsCompleted(this.state.taskId);
      }, 2000);
    }
  }

  render() {
    return (<React.Fragment>
      <div id="container">
        <div className="page_content">
          <div className="FormulaWindow-holder">
            <div className="content h-100">
              <div className="row h-100 mx-0">
                <div className="col-1 h-100">
                  <div className="row h-100">
                    <div className="col-6 border-right text-center px-0 h-100">
                      <ul className="w-100 h-100">
                        <li className="mt-0">
                          <a href="javascript:void(0)">
                            <CustomImg src="setting.svg" className="" alt="setting" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <CustomImg src="code.svg" className="" alt="code" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <CustomImg src="video1.svg" className="" alt="video" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <CustomImg src="cupen.svg" className="" alt="cupen" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <CustomImg src="quetion.svg" className="" alt="quetion" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 border-right text-center px-0 h-100">
                      <ul className="borderbox w-100 h-100">
                        <li className="my-0 border-bottom">
                          <CustomImg src="downarrow.svg" className="w-50" alt="downarrow" />
                        </li>
                        <li className="mt-0 border-bottom bg-hv">
                          <a href="javascript:void(0)">
                            <CustomImg src="fx.svg" className="w-50" alt="fx" />
                          </a>
                        </li>
                        <li className="border-bottom bg-hv">
                        <div className="dropright">
                            <a className="dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <CustomImg src="close-big.svg" className="w-50" alt="fx" />
                            </a>
                            <div className="dropdown-menu py-0">
                              <ul className="p-3 bg-transparent rounded-0">
                                <li>
                                  <label className="d-none" htmlFor="search">search</label>
                                  <input id="search" type="text" className="form-control search" disabled /></li>
                                <li onClick={() => this.setFormula("Inclination")}>Inclination</li>
                                <li onClick={() => this.setFormula("Eccentricity")}>Eccentricity</li>
                                <li onClick={() => this.setFormula("Period")}>Period</li>
                                <li onClick={() => this.setFormula("Perigee")}>Perigee</li>
                                <li onClick={() => this.setFormula("Apogee")}>Apogee</li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="border-bottom bg-hv">
                          <a href="javascript:void(0)">
                            <CustomImg src="folder.svg" className="foler" alt="folder" />
                          </a>
                        </li>
                        <li className="border-bottom bg-hv">
                          <a href="javascript:void(0)">
                            <CustomImg src="flopy.svg" className="w-50" alt="flopy" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-11">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-auto">
                      <div className="border-bottom-pink-yellow row">
                        <div className="col-md-4 p-0 border-right">
                          <div className="form-group formula-control m-0">
                            <label className="ml-3 text-uppercase" htmlFor="exampleFormControlSelect1">output column</label>
                            {/* <input type="text" className="form-control" id="output"></input>
                        <a href="" className="cls-img"><img src={require('./../../../../images/close-smal.svg')} className="img-fluid" alt="close-big" /></a> */}

                            <select ref={"sampleAnswer"} className="custom-select custom-select-top" id="exampleFormControlSelect1" disabled>
                              <option value="Inclination">Inclination</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-8 p-0">
                          <div className="form-group formula-control m-0">
                            <label className="ml-3 text-uppercase" htmlFor="data">data preview</label>
                            <input type="text" className="form-control" id="data" disabled></input>
                            <a href="javascript:void(0)" className="delete-img border-left">
                              <CustomImg src="delete.svg" className="img-fluid" alt="close-big" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-100 p-0">
                      <label htmlFor="custom-textarea"><div className="d-none">textarea</div></label>
                      <textarea id="custom-textarea" ref="custom-textarea" className="theme-text"></textarea>
                    </div>
                    <div className="mt-auto">
                      <div className="row footer-background-opacity border-top-pink-yellow">
                        <div className="col-md-2 border-right py-3">
                          <div className="text-uppercase text-right">
                            <label htmlFor="custom-select1">data type:</label>
                          </div>
                        </div>
                        <div className="col-md-3 border-right px-0 select-bg">
                          <select ref="formula" id="custom-select1" className="custom-select form-control" disabled>
                            <option>[A_WString]</option>
                            <option>[B_WString]</option>
                            <option>[C_WString]</option>
                            <option>[D_WString]</option>
                            <option>[E_WString]</option>
                            <option>[F_WString]</option>
                          </select>
                        </div>
                        <div className="col-md-2 border-right py-3 text-center">
                          <div className="text-uppercase text-right"><label htmlFor="custom-select2">size:</label></div>
                        </div>
                        <div className="col-md-3 border-right px-0 select-bg">
                          <select id="custom-select2" className="custom-select form-control doubble" disabled>
                            <option>10739734</option>
                          </select>
                        </div>
                        <div className="col-md-2 px-0">
                          <button className="red-btn w-100" onClick={this.evaluateAnswer}>
                            <div className="redcolor easy-btn">
                              <span>RUN</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>);
  }
}

export default FormulaWindow;