import { connect } from 'react-redux';
import { markAsCompleted, setCurrentTask } from '../../../Redux/Modules/Tasks';
import RepositionGate from '../../../Components/PageContainer/Games/RepositionGate/RepositionGate';

const mapStateToProps = ({ TaskReducer }) => {
    // console.log("RepositionGate", TaskReducer);
    return {
        CurrentTask: TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(RepositionGate);