import React, { Component } from 'react';
import './../../../../Styles/DecryptBankData.scss';

class DecryptBankData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedTextBox : 0,
            AnswerSheet : [],
            isCompleted : false,
            progress : [],
            taskId : 0,
            LockedIn: [ "", "", "", "", "", "", ""],
            CurrentTask : {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
          let currentTask = props.CurrentTask;
          let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                }
            }
        }
        return null;
      }

    onFocus = (index) => {
        this.setState({
            SelectedTextBox : index
        });
    }
 
    checkAnswer = async () => {
        let anyCorrect = false;
        const answers = this.state.AnswerSheet[0].answer_key;
        let is_correct = true;
        [...Array(7)].map((sdata, key) => {
            const index = key + 1;
            if (answers[key].CODE.toLowerCase().trim() === this.refs["decryptCode" + index].value.toLowerCase().trim()) {
                this.state.LockedIn[key] = "active";
                this.setState({
                    LockedIn: this.state.LockedIn
                });
            } else {
                this.state.LockedIn[key] = "";
                this.setState({
                    LockedIn: this.state.LockedIn
                });
                is_correct = false;
            }
        });

        if (is_correct) {
            anyCorrect = true;
        }

        if (anyCorrect) {
            setTimeout(async () => {
                await this.props.markAsCompleted(this.state.taskId);
            }, 1000);
        }
    }

    render() {      
        return (
            <div id="container">
          <div className="page_content">
            <div className="select-top6-satellites videos-holder decrypt-bank-data"> 
                    <div className="video-box text-center">
                    <div className="embed-responsive embed-responsive-16by9 click-disable">
                    <iframe className="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/YLp7tcTiskU?controls=0&autoplay=1&rel=0&loop=1&playlist=YLp7tcTiskU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  
                         {/*   <video width="100%" controls className="img-fluid" src="https://www.radiantmediaplayer.com/media/bbb-360p.mp4" />*/ }
                        
                    </div>
                    </div>
                <div className="enter-box">
                    <div className="enter-code">enter code</div>
                    <div className="type-box d-flex text-center">
                        {[...Array(7)].map((data, key) => {
                                const index = key + 1;
                                return (<div className={"input-code-wrapper "  + this.state.LockedIn[key]} key={index} htmlFor="decryptCode" >
                                    <input pattern="\d*" maxLength="1" ref={"decryptCode" + index} name={"decryptCode" + index} onChange={this.checkAnswer} onFocus={() => this.onFocus(index)} type="text" className={"input-code text-center " + this.state.LockedIn[key]} disabled={(this.state.LockedIn[key] === "active")} />
                                </div>)
                            })}
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export default DecryptBankData;