import React, { Component } from "react";
import "./../../../../Styles/Critical.scss";
import * as ReactDOM from 'react-dom';
import Draggable from "react-draggable";
import { const_basePage } from '../../../Global/Constants/constants';
import CustomImg from '../../../Global/CustomImg/CustomImg';

class CriticalVsNonCritical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Satellites: [
        {
          id: 1,
          country: "USA",
          position: "left",
          popupplacement: "left",
          satelliteNormalImage: "critical-img01-normal.svg",
          satelliteHoverImage: "critical-img01-hover.svg",
          satellitegreenImage: "critical-img01-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -86.7598266, left: -30, right: 1520.79, bottom: 582.39 }
        },
        {
          id: 2,
          country: "Netherlands",
          position: "left",
          popupplacement: "left",
          satelliteNormalImage: "critical-img02-normal.svg",
          satelliteHoverImage: "critical-img02-hover.svg",
          satellitegreenImage: "critical-img02-green.svg",
          isDraggable: false,
          type: "",
          currentposition: "C",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -86.7598266, left: -320, right: 1250.79, bottom: 582.39 }
        },
        {
          id: 3,
          country: "Russia",
          position: "left",
          popupplacement: "left",
          satelliteNormalImage: "critical-img03-normal.svg",
          satelliteHoverImage: "critical-img03-hover.svg",
          satellitegreenImage: "critical-img03-green.svg",
          isDraggable: false,
          type: "",
          currentposition: "C",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -86.7598266, left: -620, right: 950.79, bottom: 582.39 }
        },
        {
          id: 4,
          country: "China",
          position: "left",
          popupplacement: "left-bottom",
          satelliteNormalImage: "critical-img04-normal.svg",
          satelliteHoverImage: "critical-img04-hover.svg",
          satellitegreenImage: "critical-img04-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -335.7598266, left: -70, right: 1555.79, bottom: 325.39 }
        },
        {
          id: 5,
          country: "Japan",
          position: "left",
          popupplacement: "left-bottom",
          satelliteNormalImage: "critical-img05-normal.svg",
          satelliteHoverImage: "critical-img05-hover.svg",
          satellitegreenImage: "critical-img05-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -385.7598266, left: -330, right: 1230.79, bottom: 380.39 }
        },
        {
          id: 6,
          country: "Spain",
          position: "left",
          popupplacement: "left-bottom",
          satelliteNormalImage: "critical-img06-normal.svg",
          satelliteHoverImage: "critical-img06-hover.svg",
          satellitegreenImage: "critical-img06-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -385.7598266, left: -610, right: 920.79, bottom: 380.39 }
        },
        {
          id: 7,
          country: "Argentina",
          position: "left",
          popupplacement: "left-bottom",
          satelliteNormalImage: "critical-img07-normal.svg",
          satelliteHoverImage: "critical-img07-hover.svg",
          satellitegreenImage: "critical-img07-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -616.7598266, left: -15, right: 1515.79, bottom: 119.39 }
        },
        {
          id: 8,
          country: "Canada",
          position: "right",
          popupplacement: "left-bottom",
          satelliteNormalImage: "critical-img09-normal.svg",
          satelliteHoverImage: "critical-img09-hover.svg",
          satellitegreenImage: "critical-img09-green.svg",
          isDraggable: false,
          currentposition: "C",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -120.7598266, left: -900, right: 605.79, bottom: 620.39 }
        },
        {
          id: 9,
          country: "Saudi Arabia",
          position: "right",
          popupplacement: "right",
          satelliteNormalImage: "critical-img10-normal.svg",
          satelliteHoverImage: "critical-img10-hover.svg",
          satellitegreenImage: "critical-img10-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -120.7598266, left: -1200, right: 310.79, bottom: 620.39 }
        },
        {
          id: 10,
          country: "Germany",
          position: "right",
          popupplacement: "right",
          satelliteNormalImage: "critical-img11-normal.svg",
          satelliteHoverImage: "critical-img11-hover.svg",
          satellitegreenImage: "critical-img11-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -85.7598266, left: -1530, right: 40.79, bottom: 580.39 }
        },
        {
          id: 11,
          country: "France",
          position: "right",
          popupplacement: "right",
          satelliteNormalImage: "critical-img13-normal.svg",
          satelliteHoverImage: "critical-img13-hover.svg",
          satellitegreenImage: "critical-img13-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -335.7598266, left: -920, right: 630.79, bottom: 335.39 }
        },
        {
          id: 12,
          country: "India",
          position: "right",
          popupplacement: "right",
          satelliteNormalImage: "critical-img14-normal.svg",
          satelliteHoverImage: "critical-img14-hover.svg",
          satellitegreenImage: "critical-img14-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -335.7598266, left: -1210, right: 300.79, bottom: 335.39 }
        },
        {
          id: 13,
          country: "Israel",
          position: "right",
          popupplacement: "right-bottom",
          satelliteNormalImage: "critical-img15-normal.svg",
          satelliteHoverImage: "critical-img15-hover.svg",
          satellitegreenImage: "critical-img15-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -330.7598266, left: -1540, right: 50.79, bottom: 330.39 }
        },
        {
          id: 14,
          country: "United Kingdom",
          position: "right",
          popupplacement: "right-bottom",
          satelliteNormalImage: "critical-img16-normal.svg",
          satelliteHoverImage: "critical-img16-hover.svg",
          satellitegreenImage: "critical-img16-green.svg",
          isDraggable: false,
          currentposition: "N",
          type: "",
          threatLevel: "",
          clustor: "",
          isComplete: false,
          bound_value: { top: -620.7598266, left: -900, right: 605.79, bottom: 120.39 }
        }
      ],
      SelectedSatellite: 0,
      dragSatellite: 0,
      overlayHeight: 0,
      right: 0,
      AnswerSheet: [],
      CurrentTask : {}
    };

    this.contentBox = React.createRef();
    this.leftBox = React.createRef();
    this.mainContainer = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.CurrentTask !== state.CurrentTask) {
      let currentTask = props.CurrentTask;
      let currentTaskSolutions = currentTask.Solutions;
      if (currentTask.Solutions !== undefined) {
        const answers = currentTaskSolutions[0].answer_key;
        const updatedArray = state.Satellites;
        answers.map((data, index) => {
          updatedArray.filter((m, key) => {
            if (m.country.toLowerCase() === data.COUNTRY.toLowerCase()) {
              updatedArray[key].threatLevel = data.LEVEL;
              updatedArray[key].clustor = data.SATCOUNT;
              updatedArray[key].type = data.TYPE;
            }
          });
        });
        return {
          AnswerSheet: currentTaskSolutions,
          taskId: currentTask.id,
          //Satellites: updatedArray
        }
      }
    }
    return null;
  }

  componentDidMount() {
    this.setState({
      overlayHeight: this.contentBox.current.offsetHeight,
      right: this.leftBox.current.getBoundingClientRect().right,
      draggable_boundary: {}
    });

    setTimeout(() => {
      if (this.refs["draggable-boundary"] !== undefined) {
        let draggable_boundary = ReactDOM.findDOMNode(this.refs["draggable-boundary"]).getBoundingClientRect();
        this.setState({ draggable_boundary: draggable_boundary });
      }
    }, 1000);
  }

  handleStart = index => {
    let newSat = this.state.Satellites.filter(data => {
      return data.id === index;
    })[0];
    newSat.isDraggable = false;
    this.setState({ ...this.state.Satellites, newSat });
  };

  handleDrag = index => {
    
    let newSat = this.state.Satellites.filter(data => {
      return data.id === index;
    })[0];
    newSat.isDraggable = true;
    this.setState({ ...this.state.Satellites, newSat });
  };

  handleStop = async (e, index) => {
    
    let satellite_postion = ReactDOM.findDOMNode(this.refs["satellite_" + index]).getBoundingClientRect();

    if (e.target.id === "threatLevel" || e.target.id === "clustor") {
    } else if (e.target.className === "icon-close") {
      e.stopPropagation();
      this.handleChange(index);
      this.setState({
        SelectedSatellite: 0
      });
    } else {
      const threatLevel = this.refs["txtBoxThreatLevel" + index].value;
      const clustor = this.refs["txtBoxclustor" + index].value;
      let newSat = this.state.Satellites.filter(data => {
        return data.id === index;
      })[0];

      let variation_horizonal = 150;
      if (parseFloat(satellite_postion.left - variation_horizonal) <= parseFloat(this.state.draggable_boundary.left)) {

        newSat.popupplacement = 'left';

      }

      if (parseFloat(satellite_postion.right) >= parseFloat(this.state.draggable_boundary.right - variation_horizonal)) {

        newSat.popupplacement = 'right';
      }

      if (parseFloat(satellite_postion.top) <= parseFloat(this.state.draggable_boundary.top)) {

        newSat.popupplacement = 'top';
      }

      if (parseFloat(satellite_postion.bottom) >= parseFloat(this.state.draggable_boundary.bottom)) {

        newSat.popupplacement = 'bottom';
      }


      let position = "N";
      if (this.state.right > ReactDOM.findDOMNode(this.refs["exactsatellite" + index]).getBoundingClientRect().right) {
        position = "C";
      }

      if (!newSat.isDraggable) {
        this.setState({
          SelectedSatellite: index
        });
      }


      if (
        newSat.type.toLowerCase().trim() === position.toLowerCase().trim() &&
        newSat.threatLevel.trim() === threatLevel.trim() &&
        newSat.clustor.trim() === clustor.trim()
      ) {
        newSat.isComplete = true;
        this.setState({
          SelectedSatellite: 0
        });
      } else {
        newSat.isComplete = false;
      }

      newSat.currentposition = position;

      this.setState({ ...this.state.Satellites, newSat }, async () => {
        const completedSatellite = this.state.Satellites.filter(
          m => m.isComplete
        );        
        if (completedSatellite.length === this.state.Satellites.length) {
          await this.props.markAsCompleted(this.state.taskId);
        }
      });
    }
  };

  handleChange = index => {
    const threatLevel = this.refs["txtBoxThreatLevel" + index].value;
    const clustor = this.refs["txtBoxclustor" + index].value;
    let newSat = this.state.Satellites.filter(data => {
      return data.id === index;
    })[0];


    if (
      newSat.type.toLowerCase().trim() === newSat.currentposition.toLowerCase().trim() &&
      newSat.threatLevel.trim() === threatLevel.trim() &&
      newSat.clustor.trim() === clustor.trim()
    ) {

      newSat.isComplete = true;
    } else {
      newSat.isComplete = false;
    }
    this.setState({ ...this.state.Satellites, newSat }, async () => {
      const completedSatellite = this.state.Satellites.filter(
        m => m.isComplete
      );
      if (completedSatellite.length === this.state.Satellites.length) {
        await this.props.markAsCompleted(this.state.taskId);
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div id="container">
          <div className="page_content" >
            <div
              className={
                "videos-holder critical-holder " +
                (this.state.SelectedSatellite > 0 ? "show-overlay" : "")
              }
              id="assess-threat"
            >
              <div className="h-100">

                <div className="row h-100 m-0" id="draggable-limit" ref="draggable-boundary">
                  <div className="col-6 p-0" ref={this.leftBox} >
                    <div className="content-box-left" ref={this.contentBox}>
                      <div className="critical-head">critical</div>
                      <div className="critalcal-left-scrollbar">
                        <div className="row">
                          {this.state.Satellites.filter(
                            data => data.position === "left"
                          ).map(data => {
                            return (
                              <div
                                className="col-4 satelite-item"
                                key={data.id}
                              >
                                <Draggable enableUserSelectHack={false} bounds={data.bound_value} scale={const_basePage.scale} disabled={(data.isComplete)}
                                  onStart={() => this.handleStart(data.id)}
                                  onDrag={() => this.handleDrag(data.id)}
                                  onStop={e => this.handleStop(e, data.id)}
                                >
                                  <button
                                    className={
                                      "assessItem " +
                                      (this.state.SelectedSatellite === data.id
                                        ? "active"
                                        : "") +
                                      (data.isComplete ? " completed" : "")
                                    }
                                    type="button" id={"satellite_" + data.id} ref={"satellite_" + data.id}
                                    popup-placement={data.popupplacement}
                                    id={"btn" + data.id}
                                  >
                                     <CustomImg src={data.satelliteNormalImage} className="img-normal" alt="img-normal" />
                                     <CustomImg ref={"exactsatellite" + data.id} src={data.satelliteHoverImage} className="img-hover-active" alt="img-hover-active" />
                                     <CustomImg src={data.satellitegreenImage} className="img-green" alt="img-green" />
                                    <div className="pupupBox">
                                      <div className="popup-inner-content">
                                        {/* onClick={this.handleClick} */}
                                        <a className="close-popup assess-close-btn" href="javascript:void(0)">
                                          <em className="icon-close" name="closeicon"></em>
                                        </a>
                                        <div className="content">
                                          <h4>{data.country}</h4>
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label htmlFor={"threatLevel" + data.id}>
                                                  threat level
                                                </label>
                                                <div className="input-outer">
                                                  <input
                                                    ref={
                                                      "txtBoxThreatLevel" +
                                                      data.id
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id={"threatLevel" + data.id}
                                                    aria-describedby="emailHelp"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label htmlFor={"clustor" + data.id}>
                                                  count in cluster
                                                </label>
                                                <div className="input-outer">
                                                  <input
                                                    ref={
                                                      "txtBoxclustor" + data.id
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id={"clustor" + data.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </Draggable>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="content-box-right">
                      <div className="critical-head">non-critical</div>
                      <div className="critalcal-right-scrollbar">
                        <div className="row">
                          {this.state.Satellites.filter(
                            data => data.position === "right"
                          ).map(data => {
                            return (
                              <div
                                className="col-4 satelite-item"
                                key={data.id}
                              >
                                <Draggable enableUserSelectHack={false} bounds={data.bound_value} scale={const_basePage.scale} disabled={(data.isComplete)}
                                  onStart={() => this.handleStart(data.id)}
                                  onDrag={() => this.handleDrag(data.id)}
                                  onStop={e => this.handleStop(e, data.id)}
                                >
                                  <button
                                    className={
                                      "assessItem " +
                                      (this.state.SelectedSatellite === data.id
                                        ? "active"
                                        : "") +
                                      (data.isComplete ? " completed" : "")
                                    }
                                    type="button" ref={"satellite_" + data.id}
                                    popup-placement={data.popupplacement}
                                    id={"btn" + data.id}
                                  >
                                    <CustomImg src={data.satelliteNormalImage} className="img-normal" alt="img-normal" />
                                    <CustomImg ref={"exactsatellite" + data.id} src={data.satelliteHoverImage} className="img-hover-active" alt="img-hover-active" />
                                    <CustomImg src={data.satellitegreenImage} className="img-green" alt="img-green" />
                                    <div className="pupupBox">
                                      <div className="popup-inner-content">
                                        <a
                                          className="close-popup assess-close-btn"
                                          href="javascript:void(0)"
                                        >
                                          <em
                                            className="icon-close"
                                            name="closeicon"
                                          ></em>
                                        </a>
                                        <div className="content">
                                          <h4>{data.country}</h4>
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label htmlFor={"threatLevel" + data.id}>
                                                  threat level
                                                </label>
                                                <div className="input-outer">
                                                  <input
                                                    ref={
                                                      "txtBoxThreatLevel" +
                                                      data.id
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id={"threatLevel" + data.id}
                                                    aria-describedby="emailHelp"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label htmlFor={"clustor" + data.id}>
                                                  count in cluster
                                                </label>
                                                <div className="input-outer">
                                                  <input
                                                    ref={
                                                      "txtBoxclustor" + data.id
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id={"clustor" + data.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </Draggable>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="overlay-background"
                  style={{ height: this.state.overlayHeight }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CriticalVsNonCritical;
