import React, { Component } from 'react';
import {connect} from "react-redux";
import TaskComplexity from './TaskComplexity';
import FinalHint from './FinalHint';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';

class TaskHint extends Component {

    constructor() {
        super();
        this.state={
            CurrentTask: {},
            taskId: 0,
            showTaskHintComplex : true,
            showFinalHint : false,
            clueData:{}
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            return {
                taskId: props.CurrentTask.id,
                CurrentTask: props.CurrentTask
            }
        }
        return null;
    }

    showTaskComplexity = () => {
        if(this.state.showTaskHintComplex){
            this.props.ShowHint();
        }
        this.setState({ showTaskHint: !this.state.showTaskHint, showTaskHintComplex : !this.state.showTaskHintComplex });     
    }

    showFinalHint = (clue) => {
        this.setState({  showTaskHintComplex : false , showFinalHint :true,clueData:clue });         
    }
   
    render() {
        return (             
            <React.Fragment>
                <CustomScrollbars>
                { (this.state.taskId >= 0 && this.state.showTaskHintComplex) && 
                        <TaskComplexity showFinalHint={(clue)=>this.showFinalHint(clue)} showTaskComplexity={this.showTaskComplexity} taskId = {this.state.taskId} />}

                { (this.state.taskId >= 0 && this.state.showFinalHint) && 
                        <FinalHint ShowHint={this.props.ShowHint} showFinalHint={(clue)=>this.showFinalHint(clue)} taskId = {this.state.taskId} clueData={this.state.clueData}/>}
                </CustomScrollbars>
            </React.Fragment>
      );
    }
}

const mapStateToProps =({TaskReducer})=>{
    return {
        CurrentTask: TaskReducer.CurrentTask,
    };
}

export default connect(mapStateToProps,null)(TaskHint);