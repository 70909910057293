import { connect } from 'react-redux';
import { markAsCompleted, setCurrentTask } from '../../../Redux/Modules/Tasks';
import TopSixSatellite from './../../../Components/PageContainer/Games/TopSixSatellite/TopSixSatellite';

const mapStateToProps = ({ TaskReducer }) => {
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopSixSatellite);