import { connect } from 'react-redux';
import { getAllFiles } from './../../../Redux/Modules/Files';
import Files from '../../../Components/PageContainer/Files/Files';
import { closeFileSection } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ FilesReducer , TaskReducer }) => {
    return {
        files : FilesReducer.data,
        currentTask : TaskReducer.CurrentTask,
        isExperienceGameFinished : TaskReducer.isExperienceGameFinished,
        isGameFinish : TaskReducer.isGameFinish,
        isFileModal : TaskReducer.isFileModal
    };
};

const mapDispatchToProps = dispatch => ({
    getAllFiles: (tid) => dispatch(getAllFiles(tid)),
    closeFileSection: () => dispatch(closeFileSection())
});

export default connect(mapStateToProps, mapDispatchToProps)(Files);