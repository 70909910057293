import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import './../../../../Styles/TopSixSatellite.scss';
import CustomImg from '../../../Global/CustomImg/CustomImg';

const THREAT_LEVEL = {
   'seattle':'8',
   'portland':'9',
   'san_francisco':'4',
   'los_angeles':'9',
   'las_vegas':'1',
   'salt_lake_city':'2',
   'phoenix':'2',
   'denver':'7',
   'dallas':'3',
   'austin':'5',
   'houston':'2',
   'omaha':'2',
   'minneapolis':'3',
   'chicago':'1',
   'indianapolis':'9',
   'cinicnnati':'3',
   'nashville':'8',
   'atlanta':'1',
   'new_orleans':'7',
   'boston':'7',
   'new_york_city':'6',
   'philadelphia':'10',
   'charloette':'4',
   'jacksonville':'2'
}
class TopSixSatellite extends Component {
   constructor(props) {
      super(props);
      this.state = {
         progress: [],
         AnswerSheet : [],
         taskId : 0,
         currentMap : "map-usa",
         currentMapId : 0,
         selectedCity : [],
         CurrentTask : {},
         isDisabled : true,
         cityDetails : [{ id : 1 , city : "seattle" , status : "" },{ id : 2 , city : "portland" , status : "" },{ id : 3 , city : "san francisco" , status : "" },{ id : 4 , city : "los angeles" , status : "" },{ id : 5 , city : "las vegas" , status : "" },{ id : 6 , city : "Salt Lake City" , status : "" },{ id : 7 , city : "phoenix" , status : "" },{ id : 8 , city : "denver" , status : "" },{ id : 9 , city : "minneapolis" , status : "" },{ id : 10 , city : "omaha" , status : "" },{ id : 11 , city : "chicago" , status : "" },{ id : 12 , city : "indianapolis" , status : "" },{ id : 13 , city : "kansas city" , status : "" },{ id : 14 , city : "cincinnati" , status : "" },{ id : 15 , city : "philadelphia" , status : "" },{ id : 16 , city : "New York City" , status : "" },{ id : 17 , city : "boston" , status : "" },{ id : 18 , city : "nashville" , status : "" },{ id : 19 , city : "charlotte" , status : "" },{ id : 20 , city : "atlanta" , status : "" },{ id : 21 , city : "dallas" , status : "" },{ id : 22 , city : "austin" , status : "" },{ id : 23 , city : "houston" , status : "" },{ id : 24 , city : "new orleans" , status : "" },{ id : 25 , city : "jacksonville" , status : "" }]
       };
       
    }
    static getDerivedStateFromProps(props, state) {
      if (props.CurrentTask !== state.CurrentTask) {
        let currentTask = props.CurrentTask;
        let currentTaskSolutions = currentTask.Solutions;
          if (currentTask.Solutions !== undefined) {
              return {
                  AnswerSheet: currentTaskSolutions,
                  taskId: currentTask.id
              }
          }
      }
      return null;
    }

   openLang = (map , id) => {
      this.setState({
         currentMap: map
      });
   }
   _onMouseMove(evt,tooltip='',ref_obj) {
      
      //this.setState({ x: e.screenX, y: e.screenY });
      if(ref_obj!==undefined){
      let ref_obj_pos = ReactDOM.findDOMNode(this.refs[ref_obj]).getBoundingClientRect();
      let page_content = ReactDOM.findDOMNode(this.refs['page_content']).getBoundingClientRect();
      
      this.refs.tooltip.style.position="absolute";
      //this.refs.tooltip.style.innerHTML = '<span style="color:red;">test123</span>';
      this.refs.tooltip.style.color = "#000";
      this.refs.tooltip.style.display = "block";
      
      // this.refs.tooltip.style.left = evt.clientX - 200  + 'px';
      // this.refs.tooltip.style.top = evt.screenY  - 300 + 'px';
      this.refs.tooltip.style.top =  ref_obj_pos.y - page_content.y + 10 + 'px';
      this.refs.tooltip.style.left = ref_obj_pos.x - 100  + 'px';
      this.setState({tooltip_text:tooltip});
      }
    }
    _hideTooltip() {     
      this.refs.tooltip.style.display= "none";
    }

   checkCity = async (cityId, city) => {
      if (this.state.selectedCity.length < 5) {
         if (this.state.selectedCity.indexOf(city) === -1) {
            const updatedCityDetails = this.state.cityDetails.map(el => (el.id === cityId ? { ...el, status: "satelliteredBox" } : el));
            this.setState({
               selectedCity: this.state.selectedCity.concat(city.toLowerCase().trim()),
               cityDetails: updatedCityDetails
            });
         }
      }
   }

   verifyAnswer = async () => {
      if (this.state.selectedCity.length === 5) {
         let newCollection = [];
         this.state.AnswerSheet[0].answer_key.map((data, value) => {
            newCollection.push(data.CITY.toLowerCase().trim());
         });

         let currentCities = this.state.selectedCity;
         let newUpdatedCities = [];
         let finalCityDetails = this.state.cityDetails;
         currentCities.map((data) => {
            if (newCollection.indexOf(data.toLowerCase().trim()) > -1) {
               finalCityDetails = finalCityDetails.map(el => (el.city.toLowerCase().trim() === data.toLowerCase().trim() ? { ...el, status: "satellitegreenBox" } : el));
               newUpdatedCities = newUpdatedCities.concat(data);
            } else {
               finalCityDetails = finalCityDetails.map(el => (el.city.toLowerCase().trim() === data.toLowerCase().trim() ? { ...el, status: "" } : el));
            }
         });

         this.setState({
            selectedCity: newUpdatedCities,
            cityDetails: finalCityDetails
         }, async () => {
            const completionStatus = finalCityDetails.filter(m => m.status === "satellitegreenBox");
            if (completionStatus.length === 5) {
               setTimeout(async () => {
                  await this.props.markAsCompleted(this.state.taskId);
               }, 2000);
            }
         });
      }
   }

    render() {  

       const cityDetails =  this.state.cityDetails;

       let currentMap;
       if (this.state.currentMap === "map-one") {
         currentMap = <div className="map-one content-map active" id="map-one">
              <CustomImg src="map-one.svg" className="" alt="map-one" />
            </div>;
       } else if (this.state.currentMap === "map-two") {
         currentMap = <div className="map-two content-map active" id="map-two">
            <CustomImg src="map-two.svg" className="" alt="map-two" />
            </div>;
       } else if (this.state.currentMap === "map-five") {
         currentMap = <div className="map-three content-map active" id="map-three">
            <CustomImg src="map-three.svg" className="" alt="map-three" />
            </div>;
       } else if (this.state.currentMap === "map-four") {
         currentMap = <div className="map-four content-map active" id="map-four">
             <CustomImg src="map-four.svg" className="" alt="map-four" />
            </div>;
       } else if (this.state.currentMap === "map-three") {
         currentMap = <div className="map-five content-map active" id="map-five">
             <CustomImg src="map-five.svg" className="" alt="map-five" />
            </div>;
       } else {
          currentMap = <div className="maine-map content-map active" id="map-usa">
             <CustomImg src="satellites-map01.svg" className="" alt="map-usa" />
          </div>;
       }

        return (
            <React.Fragment>
               <div id="container">
               <div id="tooltip" ref="tooltip" style={{'display':'none'}}>{this.state.tooltip_text}</div>
          <div className="page_content">
         
               <div className="select-top6-satellites videos-holder" ref="page_content">
  
                     <div className="select-top6-satellites-scrollbar">
                        <div className="rangebox">
                           <div className="rangetxt">range</div>
                           <div className="actionBox">            
                              <button className={"btn red " + (this.state.currentMap === "map-one" ? 'active' : '')} onClick={() => this.openLang('map-one' , 1)}>
                              <span>1</span>
                              </button>
                              <button  className={"btn yellow " + (this.state.currentMap === "map-two" ? 'active' : '')}  onClick={() => this.openLang('map-two', 2)}>
                              <span>2</span>
                              </button>
                              <button  className={"btn purpule " + (this.state.currentMap === "map-three" ? 'active' : '')}  onClick={() => this.openLang('map-three',3)}>
                              <span>3</span>
                              </button>
                              <button  className={"btn blue " + (this.state.currentMap === "map-four" ? 'active' : '')}   onClick={() => this.openLang('map-four',4)}>
                              <span>4</span>
                              </button>
                              <button  className={"btn green " + (this.state.currentMap === "map-five" ? 'active' : '')}  onClick={() => this.openLang('map-five',5)}>
                              <span>5</span>
                              </button>
                              {/**  <button  className={"btn blue " + (this.state.currentMap === "map-usa" ? 'active' : '')}  onClick={() => this.openLang('map-usa', 0)}>
                                  <span>Reset</span>
                              </button>
                              */}
                           </div>
                        </div>
                        <div className="h-100 satellitesimg-box position-relative">
                           <div className="usa-maps-Box">
                              {currentMap}
                            </div>
                           <div className="outline-map">
                           
                              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 520" style={{ enableBackground: "new 0 0 800 520"}} xmlSpace="preserve">                             
                              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="199.5745" y1="591.64" x2="667.3946" y2="34.1179" gradientTransform="matrix(1 0 0 -1 0 520)">
                                 <stop  offset="0" style={{stopColor:"#ED2181"}}/>
                                 <stop  offset="0.32" style={{stopColor: "#F3555C"}}/>
                                 <stop  offset="1" style={{stopColor:"#FEBE10"}}/>
                              </linearGradient>
                              <path className="st0" d="M63.9,44.7l13.9,7.5l16-3.8l-4.2-9.8l84,19.1l99.1,15.1l86,5.6h51.4l6-7.6h11l5.5,13.8h14.6l15.1,8.9h24.1
                                 l-18.1,10.7l-11,15.6h7l9-9l2,9h11.2l18.1-19.6l15.1,15.6l26.6-9.5l17.6,13.6h-11.6L521.2,131l-8.5,22.5h7.5v37.8l9,11.2l10-7.8
                                 l2.5-16.9l-7.5-20.7l19.7-32.1l16.1,5.5l5.5,18.1l-10,12.1l6,4.5l10.5-11.6l7,18.6l-9,19.2H608l29.6-28.7v-12.1h26.1l5.5-23.1
                                 l22.6-18.6l28.1-6.5l11.6-16.1V52.7h26.1l8,25.1l13.1,11.6l-11.5,11h-9.5l-11,14.6l-4,20.6l14.1,17.1l-39.2,22.6l5.2,2.9l4.4-5.7
                                 l10.5-0.6l-14.3,11.4l-12.2,2.6l3.8,13.1l-7.1,15l-12.3-3.1l2.3,13.2l9.5,0.8l-0.4,7.9h-5.9l-9.9-3.3l-1.7-15.4l-8.1,6.5l25.8,54.4
                                 l-57.5,69l-7.4,32.1l38.3,64.5v32.4h-11.8l-33.9-36l-9.6-26.4l-22.1-13.9l-18.4,7.3l-14-7h-52.4l0.7,14.8l9.7,9l-8.4,2.6l-8.7-8.9
                                 l-3.4,9.2l-12.6,1l-6.5-8.8l-9.3-2l-3.2,3.8l-18.8-5.3L403.6,446l-11.1,22.8l4,16.5l-20-5.1l-34.2-52.5l-25.7-17.2l-12.5,18.9
                                 l-25.7-18.9l-21.1-35.3l-23.4-2.3v8l-47.9-5l-53.6-28.7v-7.4l-35.4-2.9v-10.3l-25.1-26.3l-20.5-8v-17.6l-12.6-40.7l-13.9-53.1
                                 l15-26.8v-20l8.6-8.6l15.4-44.5L63.9,44.7z M39.8,154.3l92.9,22.4l20.6-65.7l-73-12.4L63.8,81.3 M96.6,168l-17,52.4l70.6,93
                                 l-17.9,26.3 M212.9,189.8l-80.2-13.1 M143.6,304.7v-18.9h9.8L172.8,184 M153.4,111l4.8-56.7 M173.5,57.8l-4.2,21.1l15.9,27.3
                                 l-4.8,17.3l14.3,20.8h24.6 M308.2,147.5l-88.9-9.5l-9.5,70l121.4,12.4l-2.7,68.4 M314.4,75.6l-8.7,142.2 M236.7,210.8l-17.4,168.6
                                 M158.2,269l71.3,11.6l98.9,8.3h103.9 M257.3,375.2l52.8,0.8l4.4-88.3 M403.6,131.8l-93.5-2.8 M403.6,193.6l3.1-16.8v-26.9
                                 l-11.9-71.8 M406.7,176.7l67.4-6.4l-20-16.8l-2.5-17.7l5.7-15.9 M435.4,299.6l58.4-6.8l5.9,9.4h7.5l6.2-16.4L496,262.4v-8.5
                                 l-21.9-16.6v-11.4l13.1-17.9l4.4-10.1l-10.6-8.2l-6.9-19.5 M446.7,359.6l43.1-3.8v-16l11.3-18.7l6.3-19 M314,298.3h43.7v34.8l30,12
                                 h34.8l16.1,1.9l-3.1-47.5l-3.1-8.1v-12.6l3.1-25l-11.9-16.6l-20-43.8l-25-10.3h-70.8 M452.1,416l3.9-18l-9.3-22v-22.8l-8.1-6
                                 M489.7,355.8l4.8,13.9l-9.8,21h28.7v7.2l5.4,2.2 M532.4,316.9v54.3l4.2,26.8 M552.7,396.8l-6-11.3l39.6-4v-24.1l-17-44.7
                                 M501,321.1l100.9-12 M586.3,381.6l3.7,4.4l44.2-2.2V378l8.2-2.1 M675.7,312.7L658,302.1l-42.4-2.5l-13.6,7.5l2.1,7.9l24.2,16.7
                                 l19,23 M510.6,293.2l19.4-2.4l2.3-3.1l89.7-11l79-14.6 M622.1,276.7l-4.5,8.2l-11.7,6.6l-8,8.2h-7l-4.6,11.3 M513.5,285.8v-4.6h8.3
                                 l4.9-18.8l7.7-12.5l-2.1-5.6l-3.2-41.9 M526,265.2h15.5l7.4-3.1h6.2l7-12.9l7.8-4.9l-3.6-50.6 M536.5,196.9l43.4-5.5 M619.1,180.6
                                 l10.1,37.2l63.7-12.7l10.3-8.3l-9.9-7.1l5.3-15.1l-7.4-9.4l-68.1,11.5 M625.5,204.4l-3.4,21.3l-16.2,13l-1.7,16.7l14.9,11.1
                                 l19.7-8.8l5.7-19l14.7-18.4h9.8l-10.5-6.8l-16.2,10.8l-1.3-9 M604.2,249l-7.9-5.7l-17.9,0.9l-8.5-4.2 M612.8,262l-19.1,18.1
                                 M669,220.4l8.8,3.9l-2.1,11.2l12-2 M711.2,185.8l0.8-6.6l-13.4-4.5 M712,179.2l5.5-4l-5.5-17.1l2.8-13.8l-16.2-37 M720,102.4
                                 l3.9,6.6l-3.9,9v19.6l1.9,5.8 M726.4,93.4l10.3,31.4l6.7,6.1 M714.8,144.3l20.2-4.8l7.7-4 M745.7,158.9l-5.3-6.8l-28.4,6 M735,153.3
                                 l2.1,10.6 M330.6,235.6l93,1.8 M474.1,225.9h-55.7 M518.8,139.9l-8.2-12.9l-24.1-7.1 M481,189.8l39.2-2.9 M695.7,210.8l-2.9-5.7"/>
                              {/* <g>
                                 <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 638 399)" >
                                    </div>
                                    <g>
                                       <circle className="st1" cx="654.7" cy="416" r="4.2"/>
                                    </g>
                                 </g>
                              </g> */}
                              <g ref="seattle_ref" className={"cust-tooltip " + cityDetails.filter(m => m.id === 1)[0].status} data-title='tooltip' onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.seattle,"seattle_ref")}  onMouseOut={e=> this._hideTooltip()}>
                                
                                 <path  className="st2" d="M120.1,71.3H80.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h39.9c5.4,0,9.8,4.4,9.8,9.8v0
                                    C129.9,66.9,125.5,71.3,120.1,71.3z"/>
                                 <g>
                                    <g>
                                       <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 64 45)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="80.5" cy="61.4" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 90.2629 64.5815)" className="st1 st3 st4" onClick={() => this.checkCity(1,"seattle")}>seattle</text>
                                 <path className="st5" d="M120.1,71.3H80.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h39.9c5.4,0,9.8,4.4,9.8,9.8v0
                                    C129.9,66.9,125.5,71.3,120.1,71.3z"/>
                              </g>
                              <g ref="portland_ref" className={"cust-tooltip " + cityDetails.filter(m => m.id == 2)[0].status} data-toggle="tooltip" title="Disabled tooltip" onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.portland,"portland_ref")}  onMouseOut={e=> this._hideTooltip()}>
                                 <path className="st2" d="M120.3,117.2H74.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h45.5c5.4,0,9.8,4.4,9.8,9.8v0
                                    C130.1,112.8,125.7,117.2,120.3,117.2z"/>
                                 <g>
                                    <g>
                                       <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 58 91)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="74.6" cy="107.3" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 84.3723 110.5142)" className="st1 st3 st4" onClick={() => this.checkCity(2,"portland")}>portland</text>
                                 <path className="st5" d="M120.3,117.2H74.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h45.5c5.4,0,9.8,4.4,9.8,9.8v0
                                    C130.1,112.8,125.7,117.2,120.3,117.2z"/>
                              </g>
                              <g ref="san_francisco_ref" className={cityDetails.filter(m => m.id == 3)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.san_francisco,"san_francisco_ref")}  onMouseOut={e=> this._hideTooltip()}>
                                 <path className="st2" d="M104.6,250.4H47.3c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h57.3c5.4,0,9.8,4.4,9.8,9.8v0
                                    C114.4,246,110,250.4,104.6,250.4z"/>
                                 <g>
                                    <g>
                                       <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 30 224)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="46.4" cy="240.6" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 54.9028 243.7813)" className="st1 st3 st4" onClick={() => this.checkCity(3,"san francisco")}>san francisco</text>
                                 <path className="st5" d="M104.6,250.4H47.3c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h57.3c5.4,0,9.8,4.4,9.8,9.8v0
                                    C114.4,246,110,250.4,104.6,250.4z"/>
                              </g>
                              <g ref="las_vegas_ref" className={cityDetails.filter(m => m.id == 5)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.las_vegas,"las_vegas_ref")}  onMouseOut={e=> this._hideTooltip()}>
                                 <path className="st2" d="M176.8,287.4h-43c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h43c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C186.6,283,182.2,287.4,176.8,287.4z"/>
                                 <g>
                                    <g>
                                       <div    width="33" height="34" className="dotsmap" transform="matrix(1 0 0 1 118 261)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="134.3" cy="277.6" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 142.7566 280.772)" className="st1 st3 st4" onClick={() => this.checkCity(5,"las vegas")}>las vegas</text>
                                 <path className="st5" d="M176.8,287.4h-43c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h43c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C186.6,283,182.2,287.4,176.8,287.4z"/>
                              </g>

                              <g ref="salt_lake_city_ref" className={cityDetails.filter(m => m.id == 6)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.salt_lake_city,"salt_lake_city_ref")}  onMouseOut={e=> this._hideTooltip()}>
                                 <path className="st2" d="M253.2,222.3h-59.7c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h59.7c5.4,0,9.8,4.4,9.8,9.8v0
                                    C263,217.9,258.6,222.3,253.2,222.3z"/>
                                 <g>
                                    <g>
                                       <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 178 196)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="195.1" cy="212.4" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 203.6487 215.603)" className="st1 st3 st4" onClick={() => this.checkCity(6,"salt lake city")}>salt lake city</text>
                                 <path className="st5" d="M253.2,222.3h-59.7c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h59.7c5.4,0,9.8,4.4,9.8,9.8v0
                                    C263,217.9,258.6,222.3,253.2,222.3z"/>
                              </g>
                           
                             
                              <g className={cityDetails.filter(m => m.id == 8)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.denver,"denver_ref")}  onMouseOut={e=> this._hideTooltip()} ref="denver_ref">
                                 <path className="st2" d="M334.2,248h-32.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C344,243.6,339.6,248,334.2,248z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 285 221)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="301.5" cy="237.9" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 310.0884 241.4688)" className="st1 st3 st4" onClick={() => this.checkCity(8,"denver")}>denver</text>
                                 <path className="st5" d="M334.2,248h-32.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C344,243.6,339.6,248,334.2,248z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 10)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.omaha,"omaha_ref")}  onMouseOut={e=> this._hideTooltip()} ref="omaha_ref">
                                 <path className="st2" d="M437.5,225.3H406c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h31.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C447.3,220.9,442.9,225.3,437.5,225.3z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 390 199)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="407.2" cy="215.4" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 413.7983 218.7642)" className="st1 st3 st4" onClick={() => this.checkCity(10,"omaha")}>omaha</text>
                                 <path className="st5" d="M437.5,225.3H406c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h31.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C447.3,220.9,442.9,225.3,437.5,225.3z"/>
                              </g>
                             
                              <g className={cityDetails.filter(m => m.id == 12)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.indianapolis,"indianapolis_ref")}  onMouseOut={e=> this._hideTooltip()} ref="indianapolis_ref">
                                 <path className="st2" d="M549.4,232.1h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C559.2,227.7,554.8,232.1,549.4,232.1z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 533 205)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="549.2" cy="222" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 491.7617 225.6108)" className="st1 st3 st4" onClick={() => this.checkCity(12,"indianapolis")}>indianapolis</text>
                                 <path className="st5" d="M549.4,232.1h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C559.2,227.7,554.8,232.1,549.4,232.1z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 9)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.minneapolis,"minneapolis_ref")}  onMouseOut={e=> this._hideTooltip()} ref="minneapolis_ref">
                                 <path className="st2" d="M498.9,160h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C508.8,155.6,504.4,160,498.9,160z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 428 134)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="445.2" cy="150.2" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 456.2129 153.7969)" className="st1 st3 st4" onClick={() => this.checkCity(9,"minneapolis")}>minneapolis</text>
                                 <path className="st5" d="M498.9,160h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C508.8,155.6,504.4,160,498.9,160z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 11)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.chicago,"chicago_ref")}  onMouseOut={e=> this._hideTooltip()} ref="chicago_ref">
                                 <path className="st2" d="M557.8,209.9h-37.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h37.2c5.4,0,9.8,4.4,9.8,9.8v0
                                    C567.6,205.5,563.2,209.9,557.8,209.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 504 183)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="520.4" cy="200.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 527.9121 203.5244)" className="st1 st3 st4" onClick={() => this.checkCity(11,"chicago")}>chicago</text>
                                 <path className="st5" d="M557.8,209.9h-37.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h37.2c5.4,0,9.8,4.4,9.8,9.8v0
                                    C567.6,205.5,563.2,209.9,557.8,209.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 14)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.cinicnnati,"cincinnati_ref")}  onMouseOut={e=> this._hideTooltip()} ref="cincinnati_ref">
                                 <path className="st2" d="M619.1,246.9h-41.4c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h41.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C629,242.5,624.6,246.9,619.1,246.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 561 220)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="578" cy="237.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 584.605 240.4136)" className="st1 st3 st4" onClick={() => this.checkCity(14,"cincinnati")}>cincinnati</text>
                                 <path className="st5" d="M619.1,246.9h-41.4c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h41.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C629,242.5,624.6,246.9,619.1,246.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 15)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.philadelphia,"philadelphia_ref")}  onMouseOut={e=> this._hideTooltip()} ref="philadelphia_ref">
                                 <path className="st2" d="M688.8,207.9h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C698.6,203.5,694.2,207.9,688.8,207.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 671 181)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="688" cy="198.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 630.6353 201.5244)" className="st1 st3 st4" onClick={() => this.checkCity(15,"philadelphia")}>philadelphia</text>
                                 <path className="st5" d="M688.8,207.9h-54.8c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h54.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C698.6,203.5,694.2,207.9,688.8,207.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 17)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.boston,"boston_ref")}  onMouseOut={e=> this._hideTooltip()} ref="boston_ref">
                                 <path className="st2" d="M780.6,154.1h-35c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h35c5.4,0,9.8,4.4,9.8,9.8v0
                                    C790.4,149.7,786,154.1,780.6,154.1z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 729 128)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="746" cy="144.4" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 754.8838 147.5273)" className="st1 st3 st4" onClick={() => this.checkCity(17,"boston")}>boston</text>
                                 <path className="st5" d="M780.6,154.1h-35c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h35c5.4,0,9.8,4.4,9.8,9.8v0
                                    C790.4,149.7,786,154.1,780.6,154.1z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 16)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.new_york_city,"new_york_city_ref")}  onMouseOut={e=> this._hideTooltip()} ref="new_york_city_ref">
                                 <path className="st2" d="M774.4,190.1h-56.9c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h56.9c5.4,0,9.8,4.4,9.8,9.8v0
                                    C784.2,185.7,779.8,190.1,774.4,190.1z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 701 163)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="717.6" cy="180.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 726.418 183.2837)" className="st1 st3 st4" onClick={() => this.checkCity(16,"New York City")}>New York City</text>
                                 <path className="st5" d="M774.4,190.1h-56.9c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h56.9c5.4,0,9.8,4.4,9.8,9.8v0
                                    C784.2,185.7,779.8,190.1,774.4,190.1z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 21)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.dallas,"dallas_ref")}  onMouseOut={e=> this._hideTooltip()} ref="dallas_ref">
                                 <path className="st2" d="M434.8,376.7H402c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C444.6,372.3,440.2,376.7,434.8,376.7z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 386 350)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="402.2" cy="366.9" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 410.2373 370.019)" className="st1 st3 st4" onClick={() => this.checkCity(21,"dallas")}>dallas</text>
                                 <path className="st5" d="M434.8,376.7H402c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8v0
                                    C444.6,372.3,440.2,376.7,434.8,376.7z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 22)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.austin,"austin_ref")}  onMouseOut={e=> this._hideTooltip()} ref="austin_ref">
                                 <path className="st2" d="M392.6,415.9h-32.8c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C402.4,411.5,398,415.9,392.6,415.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 376 389)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="392.3" cy="406.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 357.313 409.2729)" className="st1 st3 st4" onClick={() => this.checkCity(22,"austin")}>austin</text>
                                 <path className="st5" d="M392.6,415.9h-32.8c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h32.8c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C402.4,411.5,398,415.9,392.6,415.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 23)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.houston,"houston_ref")}  onMouseOut={e=> this._hideTooltip()} ref="houston_ref">
                                 <path className="st2" d="M467.1,427.8h-37.4c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h37.4c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C476.9,423.4,472.5,427.8,467.1,427.8z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 413 401)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="429.3" cy="418" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 438.5981 421.0127)" className="st1 st3 st4" onClick={() => this.checkCity(23,"houston")}>houston</text>
                                 <path className="st5" d="M467.1,427.8h-37.4c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h37.4c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C476.9,423.4,472.5,427.8,467.1,427.8z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 24)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.new_orleans,"new_orleans_ref")}  onMouseOut={e=> this._hideTooltip()} ref="new_orleans_ref">
                                 <path className="st2" d="M567.1,415.9h-55.9c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h55.9c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C577,411.5,572.6,415.9,567.1,415.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 495 389)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="511.7" cy="406.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 521.002 409.1074)" className="st1 st3 st4" onClick={() => this.checkCity(24,"new orleans")}>new orleans</text>
                                 <path className="st5" d="M567.1,415.9h-55.9c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h55.9c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C577,411.5,572.6,415.9,567.1,415.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 20)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.atlanta,"atlanta_ref")}  onMouseOut={e=> this._hideTooltip()} ref="atlanta_ref">
                                 <path className="st2" d="M584.2,345.8h-36.9c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h36.9c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C594,341.4,589.6,345.8,584.2,345.8z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 531 319)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="547.4" cy="336" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 555.0884 339.1914)" className="st1 st3 st4" onClick={() => this.checkCity(20,"atlanta")}>atlanta</text>
                                 <path className="st5" d="M584.2,345.8h-36.9c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h36.9c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C594,341.4,589.6,345.8,584.2,345.8z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 18)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.nashville,"nashville_ref")}  onMouseOut={e=> this._hideTooltip()} ref="nashville_ref">
                                 <path className="st2" d="M592.1,302.8h-39c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h39c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C601.9,298.4,597.5,302.8,592.1,302.8z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 535 276)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="551.5" cy="293" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 559.2144 296.1685)" className="st1 st3 st4" onClick={() => this.checkCity(18,"nashville")}>nashville</text>
                                 <path className="st5" d="M592.1,302.8h-39c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h39c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C601.9,298.4,597.5,302.8,592.1,302.8z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 19)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.charloette,"charlotte_ref")}  onMouseOut={e=> this._hideTooltip()} ref="charlotte_ref">
                                 <path className="st2" d="M669,303.6h-42.2c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8H669c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C678.8,299.2,674.4,303.6,669,303.6z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 609 277)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="625.8" cy="293.8" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 633.4644 297.0029)" className="st1 st3 st4" onClick={() => this.checkCity(19,"charlotte")}>charlotte</text>
                                 <path className="st5" d="M669,303.6h-42.2c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8H669c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C678.8,299.2,674.4,303.6,669,303.6z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 25)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.jacksonville,"jacksonville_ref")}  onMouseOut={e=> this._hideTooltip()} ref="jacksonville_ref">
                                 <path className="st2" d="M695.7,396.9h-56c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h56c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C705.6,392.5,701.2,396.9,695.7,396.9z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 624 370)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="641" cy="387.1" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 648.5156 390.2583)" className="st1 st3 st4" onClick={() => this.checkCity(25,"jacksonville")}>jacksonville</text>
                                 <path className="st5" d="M695.7,396.9h-56c-5.4,0-9.8-4.4-9.8-9.8l0,0c0-5.4,4.4-9.8,9.8-9.8h56c5.4,0,9.8,4.4,9.8,9.8l0,0
                                    C705.6,392.5,701.2,396.9,695.7,396.9z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 4)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.los_angeles,"los_angeles_ref")}  onMouseOut={e=> this._hideTooltip()} ref="los_angeles_ref">
                                 <path className="st2" d="M136.7,318.4H88.3c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h48.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C146.6,314,142.2,318.4,136.7,318.4z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"  transform="matrix(1 0 0 1 70 292)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="87.1" cy="308.6" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 95.5681 311.7671)" className="st1 st3 st4" onClick={() => this.checkCity(4,"los angeles")}>los angeles</text>
                                 <path className="st5" d="M136.7,318.4H88.3c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h48.4c5.4,0,9.8,4.4,9.8,9.8v0
                                    C146.6,314,142.2,318.4,136.7,318.4z"/>
                              </g>
                              <g className={cityDetails.filter(m => m.id == 7)[0].status} onMouseOver={e=> this._onMouseMove(e,THREAT_LEVEL.phoenix,"phoenix_ref")}  onMouseOut={e=> this._hideTooltip()} ref="phoenix_ref">
                                 <path className="st2" d="M218.6,341.6h-36.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h36.2c5.4,0,9.8,4.4,9.8,9.8v0
                                    C228.5,337.2,224.1,341.6,218.6,341.6z"/>
                                 <g>
                                    <g>
                                    <div    width="33" height="34" className="dotsmap"   transform="matrix(1 0 0 1 165 315)">
                                       </div>
                                       <g>
                                          <circle className="st1" cx="181.8" cy="331.8" r="4.2"/>
                                       </g>
                                    </g>
                                 </g>
                                 <text transform="matrix(1 0 0 1 190.2615 334.9883)" className="st1 st3 st4" onClick={() => this.checkCity(7,"phoenix")}>phoenix</text>
                                 <path className="st5" d="M218.6,341.6h-36.2c-5.4,0-9.8-4.4-9.8-9.8v0c0-5.4,4.4-9.8,9.8-9.8h36.2c5.4,0,9.8,4.4,9.8,9.8v0
                                    C228.5,337.2,224.1,341.6,218.6,341.6z"/>
                              </g>
                              </svg>
                              <button onClick={this.verifyAnswer} className="red-btn position-absolute" disabled={(this.state.selectedCity.length !== 5)}>
                                 <div className="redcolor easy-btn">
                                    <span>RUN SIMULATION</span>
                                 </div>
                              </button>
                           </div>
                        </div>
                     </div>
                 
               </div>
               </div>
               </div>
            </React.Fragment>
            );
    }
}

export default TopSixSatellite;