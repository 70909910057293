const MODULE_NAME = "Hints";
const GET_INITAL_HINTS = "GET_INITAL_HINTS";
const USEHINTS = "USEHINTS";
const ADDHINTS = "USEHINTS";

const initialState = {
  data: [],
  totalHints: 3,
  remainingHints: 3,
  timeInMinutesToAddnewHints: 5,
  timeInMillisecondsToAddnewHints: 300000,
  totalScore: 100,
  totalHintUsed: 0,
  purchasedHints: 0,
  extraHintsUsed: 0,
  extraHintsAdded: 0
};

const HintReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INITAL_HINTS:
      return {
        ...state
      };
    case USEHINTS:
      return {
        ...state,
        ...action.data
      };
    case ADDHINTS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export const _dispatch_useHint = data => {
  return {
    type: USEHINTS,
    data
  };
};

export const useHint = () => dispatch => {
  try {
    
    initialState.remainingHints -= 1;
    initialState.extraHintsUsed=initialState.extraHintsAdded>0?initialState.extraHintsUsed+=1 : initialState.extraHintsUsed;
    dispatch(_dispatch_useHint(initialState));
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export const _dispatch_addHint = data => {
  return {
    type: ADDHINTS,
    data
  };
};

export const addHint = () => dispatch => {
  try {
    
    initialState.remainingHints += 1;
    initialState.extraHintsAdded += 1;
    dispatch(_dispatch_addHint(initialState));
  } catch (e) {
    console.log("Error in fetching Data");
  }
};

export default HintReducer;
