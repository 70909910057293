
import {FilesApi} from '../../Providers/Api'
const MODULE_NAME = 'Files';

const GET_ALL_FILES = "teg/GET_ALL_FILES";

const initialState = {
    data: []
};

const FilesReducer = (state = initialState, action) => {
    let action_error = null;

    if (typeof action.error !== 'undefined') {
        action_error = action.error;
    }

    switch (action.type) {
        case GET_ALL_FILES:          
            return {
                ...state, data:action.data
            };
            
        default:
            return state;
    }
};

export const getAllFiles = (tid) => (dispatch, getState) => {
    try {
        const currentState = getState(); 
        const filesCollection = currentState.TaskReducer.tasks.filter((data, key) => { return data.id <= tid }).map((data) => {
            return  data.resource;
        });
        const response  = [].concat(...filesCollection);
        //dispatch({ type: GET_ALL_FILES, data:[].concat(...response) });
        return response;
    } catch (e) {
        // console.log("Error in fetching Data");
       // dispatch({ type: FETCH_FAILURE, error: e });
       // dispatch(error('Could not get user.', parseErrors(e)));
    }
};

export default FilesReducer;

//export const { sortChange, sortReset, singleSortReset } = sortable.actionCreators;
