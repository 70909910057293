
const GET_ALL_VIDEOS = "teg/GET_ALL_VIDEOS";
const initialState = {
    data: [],
    task_name : ''
};

const VideoReducer = (state = initialState, action) => {
    let action_error = null;

    if (typeof action.error !== 'undefined') {
        action_error = action.error;
    }

    switch (action.type) {
        case GET_ALL_VIDEOS: 
            return {
               ...state, data: action.data.response , task_name : action.data.task_name
              }
            
        default:
            return state;
    }
};

export const getAllVideos = (tid) => (dispatch,getState) => {   
    try {
        const currentState = getState(); 
        const videoCollection = currentState.TaskReducer.tasks.filter((data, key) => { return data.id <= tid }).map((data) => {
            return  data.video ;
        });
        // dispatch({ type: GET_ALL_VIDEOS,  data: { response , task_name:taskName } });
         return videoCollection;
    } catch (e) {
        // console.log("Error in fetching Data");  
    }
}

export default VideoReducer;


//export const { sortChange, sortReset, singleSortReset } = sortable.actionCreators;
