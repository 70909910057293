import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import history from "./Providers/History";
import Store from "./Redux/Store";

import App from "./App";


//import 'font-awesome/css/font-awesome.min.css';
import "./Styles/ddscss.scss"; // Include our own Global styles

// Import bootstrap JS for elements
import "bootstrap";
import Progress from "react-progress-2";
var appDiv = document.getElementById("root");

render(
  <Provider store={Store}>
    <Router history={history}>
      <AppContainer>
        <div className="layout">
          <Progress.Component  />
          <App />
        </div>
      </AppContainer>
    </Router>
  </Provider>,
  appDiv
);
