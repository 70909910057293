import React from "react";
import Main from "./Components/Main/Main";
import Header from "./Containers/PageContainer/Layout/Header";
import Footer from "./Containers/PageContainer/Layout/Footer";
import SideBar from "./Components/Layout/SideBar";
import VideoSection from "./Containers/PageContainer/Video/Video";
import FileSection from "./Containers/PageContainer/Files/Files";
import ProfileSection from "./Containers/PageContainer/Profile/Profile";
import Message from "./Containers/PageContainer/Message/Message";
import NotFound from './Components/Layout/NotFound';
import ErrorBoundary from './Components/Global/ErrorBoundary';
import { withRouter } from 'react-router-dom';

class App extends React.Component {
  
	render() {
    
    if(this.props.location.pathname ==='/unauthorized') {
      
        return <NotFound />
    }
		return (
            <React.Fragment>
            <ErrorBoundary>
            <Header/>
            <div className="middle-content">
              <Main />
              <VideoSection />
              <FileSection />
              <ProfileSection />
              <SideBar />
              <Message />
            </div>
            <Footer />
            </ErrorBoundary>            
          </React.Fragment>
		);
	}
}

export default withRouter(App);
