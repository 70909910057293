import { connect } from 'react-redux';
import { markAsCompleted,setCurrentTask } from '../../../Redux/Modules/Tasks';
import AccessThreat from '../../../Components/PageContainer/Games/AccessThreat/AccessThreat';

const mapStateToProps = ({ TaskReducer }) => {
    // console.log("Access Threat 1", TaskReducer);
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessThreat);