import { combineReducers } from "redux";

import VideoReducer from "./Modules/Videos";
import MessageReducer from "./Modules/Message";
import FilesReducer from "./Modules/Files";
import TaskReducer from "./Modules/Tasks";
import UserReducer from "./Modules/User";
import HintReducer from "./Modules/Hint";

export default combineReducers({
  VideoReducer,
  MessageReducer,
  FilesReducer,
  TaskReducer,
  UserReducer,
  HintReducer
});
