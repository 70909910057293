import { connect } from 'react-redux';
import { updateUserName ,disAppearMsg} from '../../../Redux/Modules/User';
import Profile from '../../../Components/PageContainer/Profile/Profile';
import { closeProfileSection } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ UserReducer , TaskReducer}) => {
    return {
        message : UserReducer.message,
        status : UserReducer.status,
        username : UserReducer.UserName,
        nickname : UserReducer.NickName,
        isProfileModal : TaskReducer.isProfileModal
    };
};


const mapDispatchToProps = dispatch => ({
    updateUserName: (uname) => dispatch(updateUserName(uname)),
    disAppearMsg: () => dispatch(disAppearMsg()),
    closeProfileSection: () => dispatch(closeProfileSection())
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);